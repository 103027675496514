import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import Recovery from '../pages/Recovery';
import Indicated from '../pages/Indicated';

import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Finance from '../pages/Finance';
import Report from '../pages/Report';
import Closure from '../pages/Closure';
import User from '../pages/User';
import Indicate from '../pages/Indicate';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/recovery" component={Recovery} />
      <Route path="/indicated/:id" component={Indicated} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/finance" component={Finance} isPrivate />
      <Route path="/report" component={Report} isPrivate />
      <Route path="/closure" component={Closure} isPrivate isAdmin />
      <Route path="/user" component={User} isPrivate isAdmin />
      <Route path="/indicate" component={Indicate} isPrivate />

      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
