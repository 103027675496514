export function getIndicatorRequest(id) {
  return {
    type: '@indicate/GET_INDICATOR_REQUEST',
    payload: { id },
  };
}

export function getIndicatorSuccess(indicator) {
  return {
    type: '@indicate/GET_INDICATOR_SUCCESS',
    payload: { indicator },
  };
}

export function getIndicatorFailure() {
  return {
    type: '@indicate/GET_INDICATOR_FAILURE',
  };
}

export function sendMailRequest(name, idIndicator, email, phone) {
  return {
    type: '@indicate/SEND_MAIL_REQUEST',
    payload: { name, idIndicator, email, phone },
  };
}

export function sendMailSuccess() {
  return {
    type: '@indicate/SEND_MAIL_SUCCESS',
  };
}

export function sendMailFailure() {
  return {
    type: '@indicate/SEND_MAIL_FAILURE',
  };
}
