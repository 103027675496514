import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  getAssociatesSucess,
  getAssociatesFailure,
  getFinancesSuccess,
  getFinancesFailure,
  getCustomersSuccess,
  getCustomersFailure,
} from './actions';

import { signOut } from '../auth/actions';

export function* getAssociatesRequest() {
  try {
    const response = yield call(api.get, 'associates');

    yield put(getAssociatesSucess(response.data));
  } catch (err) {
    toast.error('Falha ao buscar dados, o servidor pode estar fora do ar.');
    yield put(getAssociatesFailure());
  }
}

export function* getFinancesRequest({ payload }) {
  const { start, end } = payload;
  try {
    const response = yield call(api.get, `finances?start=${start}&end=${end}`);
    const { data: finances } = response;
    yield put(getFinancesSuccess(finances));
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401)
        toast.error('Token invalido, favor entrar novamente');
      yield put(getFinancesFailure());
      yield put(signOut());
    } else {
      toast.error('Falha ao buscar dados, o servidor pode estar fora do ar.');
      yield put(getFinancesFailure());
      yield put(signOut());
    }
  }
}

export function* getCustomersRequest() {
  try {
    const response = yield call(api.get, 'customers');

    const customers = response.data;

    yield put(getCustomersSuccess(customers));
  } catch (err) {
    toast.error('Falha ao buscar dados, o servidor pode estar fora do ar.');
    yield put(getCustomersFailure());
  }
}

export default all([
  takeLatest('@associate/GET_ASSOCIATE_REQUEST', getAssociatesRequest),
  takeLatest('@associate/GET_FINANCES_REQUEST', getFinancesRequest),
  takeLatest('@associate/GET_CUSTOMERS_REQUEST', getCustomersRequest),
]);
