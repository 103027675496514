import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 500px;
  width: 90%;
  padding: 30px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 750px) {
    padding: 30px 10px;
    flex: 0;
  }

  .indicate-for {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 280px;
      height: 180px;
    }
    h1 {
      color: #aaa;
      font-size: 36px;
      text-align: center;
    }
    h3 {
      color: #aaa;
      font-size: 18px;
      text-align: center;
      margin: 20px 0;
    }
  }

  span {
    font-size: 14px;
    color: #fb6f91;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
  }

  .form {
    width: 100%;
    margin: 20px 0;

    form {
      display: flex;
      flex-direction: column;
      margin: 0px;
      width: 100%;

      input {
        background: rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 4px;
        height: 44px;
        padding: 0 15px;
        margin: 0 0 10px;

        &::placeholder {
          color: rgba(0, 0, 0, 0.3);
        }
      }

      button {
        margin-top: 20px;
        height: 44px;
        background: #3b9eff;
        font-weight: bold;
        color: #fff;
        border: 0;
        border-radius: 30px;
        font-size: 16px;
        transition: background 0.2s;

        &:hover {
          background: ${darken(0.1, '#3b9eff')};
        }
      }
    }
  }

  .other-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h2 {
      color: #aaa;
      font-size: 24px;
      text-align: center;
    }
  }

  .row-option {
    display: flex;
    flex-direction: row;
    height: 100px;
    align-items: flex-end;

    form {
      align-items: center;
      justify-content: center;
      margin: 0;
      width: 150px;

      input {
        display: none;
      }

      span {
        font-size: 14px;
        align-self: center;
        text-align: center;
        width: 180px;
        white-space: pre-wrap;
        @media only screen and (max-width: 750px) {
          width: 140px;
        }
      }
    }
    button {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border: none;
      background: none;
      width: 50px;
      height: 50px;
      margin: 0;

      &:hover {
        background: none;
      }

      p {
        color: #aaa;
      }

      img {
        width: 32px;
        height: 32px;
        margin: 0;
      }
    }
  }
`;
