import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  updateProfileSucess,
  updateProfileFailure,
  alterIndicatorSuccess,
  alterIndicatorFailure,
} from './actions';

export function* updateProfile({ payload }) {
  try {
    const {
      name,
      email,
      avatar_id,
      cpf,
      admin,
      access_site,
      ...rest
    } = payload.data;

    const profile = {
      name,
      email,
      cpf,
      admin,
      avatar_id,
      access_site,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = yield call(api.put, 'users', profile);

    toast.success('Perfil atualizado com sucesso!');

    yield put(updateProfileSucess(response.data));
  } catch (err) {
    toast.error('Erro ao atualizar perfil, confira seus dados!');
    yield put(updateProfileFailure());
  }
}

export function* alterIndicatorRequest({ payload }) {
  const { user_ixc_id } = payload;
  try {
    yield call(api.put, '/indicate', { user_ixc_id });
    toast.success('Usuário processado com sucesso');
    yield put(alterIndicatorSuccess());
  } catch {
    toast.error('Falha ao processar Usuário');
    yield put(alterIndicatorFailure());
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@indicate/ALTER_INDICATOR_REQUEST', alterIndicatorRequest),
]);
