// eslint-disable-next-line import/extensions
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';

import { formatPrice } from '~/util/format';

export default function paymentPrepareExcel(payments, customers, month) {
  const blobType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const workbook = new Excel.Workbook();
  workbook.creator = 'WNET';
  workbook.created = new Date();
  const worksheet = workbook.addWorksheet(`Fechamento ${month}`);
  worksheet.columns = [
    { header: 'Associado', key: 'associate', width: 40 },
    { header: 'Cod. Empresa', key: 'company', width: 13 },
    { header: 'Cod. IXC', key: 'ixc_id', width: 9 },
    { header: 'Valor', key: 'value', width: 12 },
    { header: 'Observação', key: 'obs', width: 50 },
  ];
  payments.forEach(payment => {
    payment.obj.forEach(obj => {
      const auxCustomer = customers.find(
        customer => customer.id === obj.userId
      );
      worksheet.addRow({
        associate: auxCustomer ? auxCustomer.name : 'Não encontrado',
        company: obj.company,
        ixc_id: obj.ixc_id,
        value: formatPrice(obj.value),
        obs: obj.obs,
      });
    });
  });
  worksheet.getCell('A1').style.font = { bold: true };
  worksheet.getCell('A1').border = {
    top: { style: 'medium' },
    left: { style: 'medium' },
    bottom: { style: 'medium' },
    right: { style: 'thin' },
  };
  worksheet.getCell('B1').style.font = { bold: true };
  worksheet.getCell('B1').border = {
    top: { style: 'medium' },
    left: { style: 'thin' },
    bottom: { style: 'medium' },
    right: { style: 'thin' },
  };
  worksheet.getCell('C1').style.font = { bold: true };
  worksheet.getCell('C1').border = {
    top: { style: 'medium' },
    left: { style: 'thin' },
    bottom: { style: 'medium' },
    right: { style: 'thin' },
  };
  worksheet.getCell('D1').style.font = { bold: true };
  worksheet.getCell('D1').border = {
    top: { style: 'medium' },
    left: { style: 'thin' },
    bottom: { style: 'medium' },
    right: { style: 'thin' },
  };
  worksheet.getCell('E1').style.font = { bold: true };
  worksheet.getCell('E1').border = {
    top: { style: 'medium' },
    left: { style: 'thin' },
    bottom: { style: 'medium' },
    right: { style: 'medium' },
  };
  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], { type: blobType });
    FileSaver.saveAs(blob, `Fechamento ${month}`);
  });
}
