import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import associate from './associate/reducer';
import indicate from './indicate/reducer';
import payment from './payment/reducer';

export default combineReducers({
  auth,
  user,
  associate,
  indicate,
  payment,
});
