export function signInRequest(cpf, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { cpf, password },
  };
}

export function signInSucess(token, user, members) {
  return {
    type: '@auth/SIGN_IN_SUCESS',
    payload: { token, user, members },
  };
}

export function recoveryRequest(cpf) {
  return {
    type: '@auth/RECOVERY_REQUEST',
    payload: { cpf },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
