import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  flex: 1;
  max-width: 1050px;
  width: 90%;
  margin: 30px;

  background: #fff;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (max-width: 750px) {
    margin: 10px;
  }
`;

export const Panel = styled.div`
  width: 300px;
  height: 150px;
  margin: 50px auto;

  border: 1px solid #eee;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);

  div {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    margin: 0 auto;
    padding-top: 40px;

    strong {
      font-size: 28px;
      color: #656565;
    }

    span {
      font-size: 23px;
      color: #999999;
    }
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const AssociateList = styled.div`
  position: relative;
  margin: 50px 50px 0;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-bottom: 1px solid #eee;

  span {
    font-size: 20px;
    color: #999;
    font-weight: none;
  }

  ul {
    align-items: center;
    justify-content: center;
    max-width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 750px) {
    margin: 10px 10px 0;
  }
`;

export const Associate = styled.div``;

export const List = styled.li`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-content: center;
  align-items: center;

  cursor: pointer;

  margin: 20px 15px 0px;

  img {
    margin: 0;
    width: 48px;
    height: 48px;
    background: #a8a4d2;

    border-radius: 50px;
    border: 3px solid ${props => (props.payment ? '#00D237' : '#FF0000')};
  }

  span {
    text-transform: capitalize;
    color: #999;
    font-size: 12px;
    margin-top: 4px;
  }
`;

export const Info = styled.div`
  color: #fff;
  text-align: center;

  position: absolute;
  z-index: 1;
  width: 200px;
  margin-top: 65px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 15px 5px;
  display: ${props => (props.visible ? 'block' : 'none')};

  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: calc(50% - 20px);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid rgba(0, 0, 0, 0.8);
  }

  span {
    color: #fff;
  }

  hr {
    border: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: 10px 0 10px;
  }

  p {
    margin-top: 10px;
    font-size: 12px;
  }
`;

export const PageActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    color: #574b95;
    background: transparent;
    padding: 10px 14px;
    border-radius: 4px;
    transition: 1s;
    &:hover {
      color: #b009f1;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
