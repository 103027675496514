import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import pt from 'date-fns/locale/pt';
import formatData from './formatData';

import {
  getFinancesRequest,
  getCustomersRequest,
} from '~/store/modules/associate/actions';

import { perPage } from '~/util/constantsVariables';

import avatar from '~/assets/avatar2.png';
import LoadingSpinner from '~/components/LoadingSpinner/index';
import {
  Container,
  Panel,
  AssociateList,
  Associate,
  List,
  Info,
  PageActions,
} from './styles';

export default function Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.profile);
  const loading = useSelector(state => state.associate.loading);
  const finances = useSelector(state => state.associate.finances);
  const prevAssociates = useSelector(state => state.associate.members);
  const prevCustomers = useSelector(state => state.associate.customers);

  const [quantity, setQuantity] = useState(0);
  const [confirmedCommis, setConfirmedCommis] = useState('R$ 0,00');

  const [pageLv1, setPageLv1] = useState(0);
  const [pageLv2, setPageLv2] = useState(0);
  const [pageLv3, setPageLv3] = useState(0);
  const [pageLv4, setPageLv4] = useState(0);
  const [pageLv5, setPageLv5] = useState(0);

  const [dataLvl1, setDataLvl1] = useState([]);
  const [dataLvl2, setDataLvl2] = useState([]);
  const [dataLvl3, setDataLvl3] = useState([]);
  const [dataLvl4, setDataLvl4] = useState([]);
  const [dataLvl5, setDataLvl5] = useState([]);

  const [dataPerPageLvl1, setDataPerPageLvl1] = useState([]);
  const [dataPerPageLvl2, setDataPerPageLvl2] = useState([]);
  const [dataPerPageLvl3, setDataPerPageLvl3] = useState([]);
  const [dataPerPageLvl4, setDataPerPageLvl4] = useState([]);
  const [dataPerPageLvl5, setDataPerPageLvl5] = useState([]);

  function handlePage(lvl, action) {
    switch (lvl) {
      case 1:
        setPageLv1(action === 'back' ? pageLv1 - 1 : pageLv1 + 1);
        break;

      case 2:
        setPageLv2(action === 'back' ? pageLv2 - 1 : pageLv2 + 1);
        break;

      case 3:
        setPageLv3(action === 'back' ? pageLv3 - 1 : pageLv3 + 1);
        break;

      case 4:
        setPageLv4(action === 'back' ? pageLv4 - 1 : pageLv4 + 1);
        break;
      default:
        setPageLv5(action === 'back' ? pageLv5 - 1 : pageLv5 + 1);
        break;
    }
  }

  useEffect(() => {
    setDataPerPageLvl1(
      dataLvl1.slice(pageLv1 * perPage, pageLv1 * perPage + perPage)
    );
  }, [dataLvl1, pageLv1]);

  useEffect(() => {
    setDataPerPageLvl2(
      dataLvl2.slice(pageLv2 * perPage, pageLv2 * perPage + perPage)
    );
  }, [dataLvl2, pageLv2]);

  useEffect(() => {
    setDataPerPageLvl3(
      dataLvl3.slice(pageLv3 * perPage, pageLv3 * perPage + perPage)
    );
  }, [dataLvl3, pageLv3]);

  useEffect(() => {
    setDataPerPageLvl4(
      dataLvl4.slice(pageLv4 * perPage, pageLv4 * perPage + perPage)
    );
  }, [dataLvl4, pageLv4]);

  useEffect(() => {
    setDataPerPageLvl5(
      dataLvl5.slice(pageLv5 * perPage, pageLv5 * perPage + perPage)
    );
  }, [dataLvl5, pageLv5]);

  useEffect(() => {
    function loadCustomers() {
      dispatch(getCustomersRequest());
    }

    function loadFinances() {
      const currentDate = new Date();
      const start = format(startOfMonth(currentDate), "yyyy'-'MM'-'dd", {
        locale: pt,
      });
      const end = format(endOfMonth(currentDate), "yyyy'-'MM'-'dd", {
        locale: pt,
      });

      dispatch(getFinancesRequest(start, end));
    }

    if (user) {
      if (user.admin) loadCustomers();
    }
    loadFinances();
  }, [user, dispatch]);

  useEffect(() => {
    if (finances.length > 0 && user) {
      if (user.admin && prevCustomers.length > 0) {
        const { customers, confirmed_commis, qtt_items } = formatData(
          true,
          finances,
          prevCustomers
        );

        setDataLvl1(customers);
        setConfirmedCommis(confirmed_commis);
        setQuantity(qtt_items);
      } else if (!user.admin && prevAssociates.length > 0) {
        const {
          lvl1,
          lvl2,
          lvl3,
          lvl4,
          lvl5,
          confirmed_commis,
          qtt_items,
        } = formatData(false, finances, prevAssociates);

        setDataLvl1(lvl1);
        setDataLvl2(lvl2);
        setDataLvl3(lvl3);
        setDataLvl4(lvl4);
        setDataLvl5(lvl5);
        setConfirmedCommis(confirmed_commis);
        setQuantity(qtt_items);
      } else {
        setDataLvl1([]);
        setDataLvl2([]);
        setDataLvl3([]);
        setDataLvl4([]);
        setDataLvl5([]);
      }
    }
  }, [finances, prevCustomers, prevAssociates, user]);

  function handleVisible(lvl, item) {
    switch (lvl) {
      case 1:
        setDataLvl1(
          dataLvl1.map(dataItem =>
            dataItem.id === item.id
              ? { ...dataItem, visible: !item.visible }
              : { ...dataItem, visible: false }
          )
        );
        break;
      case 2:
        setDataLvl2(
          dataLvl2.map(dataItem =>
            dataItem.id === item.id
              ? { ...dataItem, visible: !item.visible }
              : { ...dataItem, visible: false }
          )
        );
        break;
      case 3:
        setDataLvl3(
          dataLvl3.map(dataItem =>
            dataItem.id === item.id
              ? { ...dataItem, visible: !item.visible }
              : { ...dataItem, visible: false }
          )
        );
        break;
      case 4:
        setDataLvl4(
          dataLvl4.map(dataItem =>
            dataItem.id === item.id
              ? { ...dataItem, visible: !item.visible }
              : { ...dataItem, visible: false }
          )
        );
        break;
      default:
        setDataLvl5(
          dataLvl5.map(dataItem =>
            dataItem.id === item.id
              ? { ...dataItem, visible: !item.visible }
              : { ...dataItem, visible: false }
          )
        );
        break;
    }
  }

  return (
    <Container>
      {loading && <LoadingSpinner />}
      <header>
        <Panel>
          <div>
            <strong>{quantity}</strong>
            <span>Indicados</span>
          </div>
        </Panel>
        <Panel>
          <div>
            <strong>{confirmedCommis}</strong>
            <span>Comissão confirmada</span>
          </div>
        </Panel>
      </header>
      {user &&
        (user.admin ? (
          <AssociateList>
            <span>Primeiro Nível</span>
            <PageActions>
              <button
                type="button"
                onClick={() => handlePage(1, 'back')}
                disabled={pageLv1 < 1}
              >
                <MdKeyboardArrowLeft size={20} />
                Voltar
              </button>
              <button
                type="button"
                onClick={() => handlePage(1, 'next')}
                disabled={dataLvl1.length - perPage * (pageLv1 + 1) <= 0}
              >
                Próximo
                <MdKeyboardArrowRight size={20} />
              </button>
            </PageActions>
            <div>
              <ul>
                {dataPerPageLvl1.map(customer => (
                  <Associate key={customer.id}>
                    <List
                      key={customer.id}
                      payment={customer.payment}
                      onMouseEnter={() => handleVisible(1, customer)}
                      onMouseLeave={() => handleVisible(1, customer)}
                    >
                      <img
                        src={customer.avatar ? customer.avatar.url : avatar}
                        alt="avatar"
                      />
                      <Info visible={customer.visible}>
                        <span>{customer.name}</span>
                        <hr />
                        <p>E-mail: {customer.email.toLowerCase()}</p>
                      </Info>
                      <span>{customer.name.toLowerCase().split(' ', 1)}</span>
                    </List>
                  </Associate>
                ))}
              </ul>
            </div>
          </AssociateList>
        ) : (
          <>
            <AssociateList>
              <span>Primeiro Nível</span>
              <PageActions>
                <button
                  type="button"
                  onClick={() => handlePage(1, 'back')}
                  disabled={pageLv1 < 1}
                >
                  <MdKeyboardArrowLeft size={20} />
                  Voltar
                </button>
                <button
                  type="button"
                  onClick={() => handlePage(1, 'next')}
                  disabled={dataLvl1.length - perPage * (pageLv1 + 1) <= 0}
                >
                  Próximo
                  <MdKeyboardArrowRight size={20} />
                </button>
              </PageActions>
              <div>
                <ul>
                  {dataPerPageLvl1.map(associate => (
                    <Associate key={associate.id}>
                      <List
                        key={associate.id}
                        payment={associate.payment}
                        onMouseEnter={() => handleVisible(1, associate)}
                        onMouseLeave={() => handleVisible(1, associate)}
                      >
                        <img
                          src={
                            associate.user.avatar
                              ? associate.user.avatar.url
                              : avatar
                          }
                          alt="avatar"
                        />
                        <Info visible={associate.visible}>
                          <span>{associate.user.name}</span>
                          <hr />
                          <p>E-mail: {associate.user.email.toLowerCase()}</p>
                          <p>Comissão: {associate.percent_month} %</p>
                        </Info>
                        <span>
                          {associate.user.name.toLowerCase().split(' ', 1)}
                        </span>
                      </List>
                    </Associate>
                  ))}
                </ul>
              </div>
            </AssociateList>
            <AssociateList>
              <span>Segundo Nível</span>
              <PageActions>
                <button
                  type="button"
                  onClick={() => handlePage(2, 'back')}
                  disabled={pageLv2 < 1}
                >
                  <MdKeyboardArrowLeft size={20} />
                  Voltar
                </button>
                <button
                  type="button"
                  onClick={() => handlePage(2, 'next')}
                  disabled={dataLvl2.length - perPage * (pageLv2 + 1) <= 0}
                >
                  Próximo
                  <MdKeyboardArrowRight size={20} />
                </button>
              </PageActions>
              <div>
                <ul>
                  {dataPerPageLvl2.map(associate => (
                    <Associate key={associate.id}>
                      <List
                        key={associate.id}
                        payment={associate.payment}
                        onMouseEnter={() => handleVisible(2, associate)}
                        onMouseLeave={() => handleVisible(2, associate)}
                      >
                        <img
                          src={
                            associate.user.avatar
                              ? associate.user.avatar.url
                              : avatar
                          }
                          alt="avatar"
                        />
                        <Info visible={associate.visible}>
                          <span>{associate.user.name}</span>
                          <hr />
                          <p>E-mail: {associate.user.email.toLowerCase()}</p>
                          <p>Comissão: {associate.percent_month} %</p>
                        </Info>
                        <span>
                          {associate.user.name.toLowerCase().split(' ', 1)}
                        </span>
                      </List>
                    </Associate>
                  ))}
                </ul>
              </div>
            </AssociateList>
            <AssociateList>
              <span>Terceiro Nível</span>
              <PageActions>
                <button
                  type="button"
                  onClick={() => handlePage(3, 'back')}
                  disabled={pageLv3 < 1}
                >
                  <MdKeyboardArrowLeft size={20} />
                  Voltar
                </button>
                <button
                  type="button"
                  onClick={() => handlePage(3, 'next')}
                  disabled={dataLvl3.length - perPage * (pageLv3 + 1) <= 0}
                >
                  Próximo
                  <MdKeyboardArrowRight size={20} />
                </button>
              </PageActions>
              <div>
                <ul>
                  {dataPerPageLvl3.map(associate => (
                    <Associate key={associate.id}>
                      <List
                        key={associate.id}
                        payment={associate.payment}
                        onMouseEnter={() => handleVisible(3, associate)}
                        onMouseLeave={() => handleVisible(3, associate)}
                      >
                        <img
                          src={
                            associate.user.avatar
                              ? associate.user.avatar.url
                              : avatar
                          }
                          alt="avatar"
                        />
                        <Info visible={associate.visible}>
                          <span>{associate.user.name}</span>
                          <hr />
                          <p>E-mail: {associate.user.email.toLowerCase()}</p>
                          <p>Comissão: {associate.percent_month} %</p>
                        </Info>
                        <span>
                          {associate.user.name.toLowerCase().split(' ', 1)}
                        </span>
                      </List>
                    </Associate>
                  ))}
                </ul>
              </div>
            </AssociateList>
            <AssociateList>
              <span>Quarto Nível</span>
              <PageActions>
                <button
                  type="button"
                  onClick={() => handlePage(4, 'back')}
                  disabled={pageLv4 < 1}
                >
                  <MdKeyboardArrowLeft size={20} />
                  Voltar
                </button>
                <button
                  type="button"
                  onClick={() => handlePage(4, 'next')}
                  disabled={dataLvl4.length - perPage * (pageLv4 + 1) <= 0}
                >
                  Próximo
                  <MdKeyboardArrowRight size={20} />
                </button>
              </PageActions>
              <div>
                <ul>
                  {dataPerPageLvl4.map(associate => (
                    <Associate key={associate.id}>
                      <List
                        key={associate.id}
                        payment={associate.payment}
                        onMouseEnter={() => handleVisible(4, associate)}
                        onMouseLeave={() => handleVisible(4, associate)}
                      >
                        <img
                          src={
                            associate.user.avatar
                              ? associate.user.avatar.url
                              : avatar
                          }
                          alt="avatar"
                        />
                        <Info visible={associate.visible}>
                          <span>{associate.user.name}</span>
                          <hr />
                          <p>E-mail: {associate.user.email.toLowerCase()}</p>
                          <p>Comissão: {associate.percent_month} %</p>
                        </Info>
                        <span>
                          {associate.user.name.toLowerCase().split(' ', 1)}
                        </span>
                      </List>
                    </Associate>
                  ))}
                </ul>
              </div>
            </AssociateList>
            <AssociateList>
              <span>Quinto Nível</span>
              <PageActions>
                <button
                  type="button"
                  onClick={() => handlePage(5, 'back')}
                  disabled={pageLv5 < 1}
                >
                  <MdKeyboardArrowLeft size={20} />
                  Voltar
                </button>
                <button
                  type="button"
                  onClick={() => handlePage(5, 'next')}
                  disabled={dataLvl5.length - perPage * (pageLv5 + 1) <= 0}
                >
                  Próximo
                  <MdKeyboardArrowRight size={20} />
                </button>
              </PageActions>
              <div>
                <ul>
                  {dataPerPageLvl5.map(associate => (
                    <Associate key={associate.id}>
                      <List
                        key={associate.id}
                        payment={associate.payment}
                        onMouseEnter={() => handleVisible(5, associate)}
                        onMouseLeave={() => handleVisible(5, associate)}
                      >
                        <img
                          src={
                            associate.user.avatar
                              ? associate.user.avatar.url
                              : avatar
                          }
                          alt="avatar"
                        />
                        <Info visible={associate.visible}>
                          <span>{associate.user.name}</span>
                          <hr />
                          <p>E-mail: {associate.user.email.toLowerCase()}</p>
                          <p>Comissão: {associate.percent_month} %</p>
                        </Info>
                        <span>
                          {associate.user.name.toLowerCase().split(' ', 1)}
                        </span>
                      </List>
                    </Associate>
                  ))}
                </ul>
              </div>
            </AssociateList>
          </>
        ))}
    </Container>
  );
}
