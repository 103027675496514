import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content, Panel } from './styles';

export default function AuthLayout({ children }) {
  const path = window.location.pathname.split('/')[1];
  return (
    <Wrapper>
      <Panel path={path === 'indicated'}>
        <Content path={path === 'indicated'}>{children}</Content>
      </Panel>
    </Wrapper>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
