import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { numberMask, numberUnmask } from '~/services/mask';

import {
  getIndicatorFailure,
  sendMailRequest,
} from '~/store/modules/indicate/actions';

import whatsApp from '~/assets/whatsApp.svg';
import copy from '~/assets/copy.svg';
import LoadingSpinner from '~/components/LoadingSpinner/index';
import { Container } from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string()
    .email('Digite um e-mail válido ex(x@x.com)')
    .required('O email é obrigatório ex(x@x.com)'),
  number: Yup.string()
    .min(15, 'Digite um número valido ex((27) 9 9999-9999)')
    .required('O número é obrigatório ex((27) 9 9999-9999)'),
});

const schemaWhats = Yup.object().shape({
  number: Yup.string()
    .min(15, 'Digite um número valido\nex((27) 9 9999-9999)')
    .required('O número é obrigatório\nex((27) 9 9999-9999)'),
});

export default function Indicate() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const loading = useSelector(state => state.indicate.loading);
  const [numberInput, setNumberInput] = useState('');
  const [emailInput, setEmailInput] = useState('');

  useEffect(() => {
    dispatch(getIndicatorFailure());
  }, [dispatch]);

  function numberChange(e) {
    setNumberInput(numberMask(e.target.value));
  }

  function copyToClipboard() {
    const textField = document.createElement('textarea');
    textField.innerText = `${process.env.REACT_APP_BASE_URL_SITE}/indicated/${profile.id}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toast.success('Link copiado!');
  }

  function handleSubmitWhats() {
    window.open(
      `https://api.whatsapp.com/send?phone=${55 +
        numberUnmask(
          numberInput
        )}&text=Ol%C3%A1,%0AVoc%C3%AA%20foi%20indicado%20para%20W-NET%20TELLECOM%20por%20${profile.name.split(
        ' ',
        1
      )}!%0ABasta%20clicar%20no%20link:%0A${
        process.env.REACT_APP_BASE_URL_SITE
      }/indicated/${profile.id}`
    );
  }

  function handleSubmit({ name, email, number }) {
    const idIndicator = profile.id;
    dispatch(sendMailRequest(name, idIndicator, email, number));
  }

  return (
    <Container>
      {loading && <LoadingSpinner />}
      <div className="indicate-for">
        <h1>Indique amigos e aumente seus benefícios!</h1>
      </div>
      <div className="form">
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome Completo" />
          <Input
            name="email"
            value={emailInput}
            onChange={e => setEmailInput(e.target.value)}
            placeholder="Endereço de e-mail"
          />
          <Input
            maxLength="15"
            name="number"
            value={numberInput}
            onChange={numberChange}
            placeholder="Telefone"
          />
          <button type="submit">{loading ? 'Eviando...' : 'Enviar'}</button>
        </Form>
      </div>
      <div className="other-options">
        <h2>Não sabe os dados?</h2>
        <h2>Envie sua indicação via:</h2>
        <div className="row-option">
          <Form schema={schemaWhats} onSubmit={handleSubmitWhats}>
            <button type="submit">
              <Input
                maxLength="15"
                name="number"
                value={numberInput}
                onChange={numberChange}
              />
              <img src={whatsApp} alt="whatsApp" />
            </button>
          </Form>
          <button type="button" onClick={() => copyToClipboard()}>
            <p>Copiar o link</p>
            <img src={copy} alt="" />
          </button>
        </div>
      </div>
    </Container>
  );
}
