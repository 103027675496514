import produce from 'immer';

const INITIAL_STATE = {
  members: [],
  finances: [],
  customers: [],
  loading: false,
};

export default function associate(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCESS': {
        draft.members = action.payload.members;
        break;
      }
      case '@associate/GET_FINANCES_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@associate/GET_FINANCES_SUCCESS': {
        draft.finances = action.payload.finances;
        draft.loading = false;
        break;
      }
      case '@associate/GET_FINANCES_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@associate/GET_CUSTOMERS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@associate/GET_CUSTOMERS_SUCCESS': {
        draft.customers = action.payload.customers;
        draft.loading = false;
        break;
      }
      case '@associate/GET_CUSTOMERS_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.indicator = {};
        draft.members = [];
        draft.customers = [];
        draft.finances = [];
        break;
      }
      default:
    }
  });
}
