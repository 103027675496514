import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1050px;
  width: 90%;
  margin: 30px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  header {
    display: flex;
    align-self: center;
    align-items: center;
    margin-bottom: 40px;

    button {
      border: 0;
      background: none;
    }

    strong {
      color: #fff;
      font-size: 28px;
      font-weight: 100;
      text-align: center;
      width: 280px;
    }
  }
`;

export const RowPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }
`;

export const Panel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  height: 100px;
  background: #fff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  margin: ${props => (props.color === 2 ? '0 10px' : '0')};
  margin-bottom: 20px;

  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    strong {
      font-size: 32px;
      color: #656565;
    }
    p {
      font-size: 18px;
      color: #999;
    }
  }
  div:first-child {
    background: ${props => {
      switch (props.color) {
        case 1:
          return '#1F7ECC';
        case 2:
          return '#f00';
        default:
          return '#00D237';
      }
    }};
    margin-right: 10px;
    width: 15px;
  }
`;

export const AssociateList = styled.ul`
  list-style: none;
  width: 100%;
  height: calc(100vh - 375px);
  overflow: auto;

  h1 {
    display: flex;
    justify-content: center;
    color: #fff;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background: #fff;
    padding: 10px 30px;
    border-radius: 6px;
    margin-bottom: 10px;

    @media only screen and (max-width: 750px) {
      flex-direction: column;
      height: 100%;
      padding: 10px;
    }
  }
`;

export const Associate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 300px;

  img {
    width: 48px;
    height: 48px;
    background: #a8a4d2;
    margin-right: 10px;
    border-radius: 25px;
    border: 4px solid ${props => (props.Status ? '#00D237' : '#FF0000')};
  }
  @media only screen and (max-width: 750px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  flex: ${props => (props.flex ? '1' : 'none')};
  margin: 10px 0;
  justify-content: space-between;

  @media only screen and (max-width: 750px) {
    flex-direction: row;
    width: 100%;
    padding: 0 20px;
  }
`;

export const AssociateInfo = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 5px;

  strong {
    text-transform: ${props => (props.capitalize ? 'capitalize' : 'none')};
    color: #221f1f;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  p {
    color: #221f1f;
    font-size: 12px;
  }
`;

export const PageActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    color: #fff;
    background: transparent;
    padding: 10px 14px;
    border-radius: 4px;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
