import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

import { cnpjMask, cpfMask } from '~/services/mask';

import logo from '~/assets/logo_wnet.png';

const schema = Yup.object().shape({
  cpf: Yup.string()
    .min(11)
    .required('O cpf é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const [documentId, setDocumentId] = useState('');

  function handleSubmit({ cpf, password }) {
    dispatch(signInRequest(cpf, password));
  }

  function handleChange(e) {
    setDocumentId(
      e.target.value.length > 14
        ? cnpjMask(e.target.value)
        : cpfMask(e.target.value)
    );
  }

  return (
    <>
      <img src={logo} alt="wnet" />

      <Form schema={schema} onSubmit={handleSubmit}>
        <Input
          maxLength="18"
          name="cpf"
          value={documentId}
          onChange={handleChange}
          placeholder="Seu CPF / CNPJ"
        />
        <Input
          name="password"
          type="password"
          placeholder="Sua senha secreta"
        />

        <button type="submit">{loading ? 'Carregando...' : 'Acessar'}</button>
      </Form>

      <Link to="recovery">Esqueceu a senha?</Link>
    </>
  );
}
