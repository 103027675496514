import { formatPrice } from '~/util/format';

export default function formatFinances(admin, finances, customers) {
  let aux_commis_expected = 0;
  let aux_commission = 0;

  if (admin) {
    const auxCustomers = customers.map(customer => {
      let total_commis_expected = 0;
      let total_commission = 0;
      finances.map(finance => {
        if (finance.higher_id === customer.id) {
          total_commis_expected += finance.commis_expected;
          total_commission += finance.commission;
          aux_commis_expected += finance.commis_expected;
          aux_commission += finance.commission;
        }
        return null;
      });

      return {
        ...customer,
        total_commis_expected: formatPrice(total_commis_expected),
        total_commis_to_confirm: formatPrice(
          total_commis_expected - total_commission
        ),
        total_commission: formatPrice(total_commission),
      };
    });

    return {
      auxCustomers,
      commis_expected: formatPrice(aux_commis_expected),
      commis_to_confirm: formatPrice(aux_commis_expected - aux_commission),
      commission: formatPrice(aux_commission),
    };
  }

  const auxFinances = finances.map(finance => ({
    ...finance,
    formated_due_date: new Date(finance.due_date).toLocaleDateString('pt-br'),
    formated_payment_date: finance.payment_date
      ? new Date(finance.payment_date).toLocaleDateString('pt-br')
      : ' / / ',
    formated_value: formatPrice(finance.value),
    formated_payment_value: formatPrice(finance.payment_value),
    formated_commission: formatPrice(finance.commission),
  }));

  aux_commis_expected = finances
    .map(associate => associate.commis_expected)
    .reduce((acc, associate) => associate + acc);

  aux_commission = finances
    .map(associate => associate.commission)
    .reduce((acc, associate) => associate + acc);

  return {
    auxFinances,
    commis_expected: formatPrice(aux_commis_expected),
    commis_to_confirm: formatPrice(aux_commis_expected - aux_commission),
    commission: formatPrice(aux_commission),
  };
}
