/* eslint-disable prefer-const */
import { formatPrice } from '~/util/format';

export default function formatData(admin, finances, items) {
  function paymentStatus(itemId, associateLevel) {
    if (admin) {
      const payment = finances.filter(
        finance =>
          itemId === finance.higher_id && finance.payment_value === null
      );
      const exist = finances.filter(finance => itemId === finance.user.id);

      if (payment.length > 0 || exist.length === 0) {
        return false;
      }
      return true;
    }
    const payment = finances.filter(
      finance =>
        itemId === finance.user.id &&
        associateLevel === finance.level &&
        finance.payment_value === null
    );
    const exist = finances.filter(
      finance => itemId === finance.user.id && associateLevel === finance.level
    );

    if (payment.length > 0 || exist.length === 0) {
      return false;
    }
    return true;
  }

  function confirmedComiss(itemId, associateLevel) {
    let confirmed_commis = 0;
    if (admin) {
      finances.forEach(finance => {
        if (itemId === finance.higher_id) {
          confirmed_commis += finance.commission;
        }
      });

      return confirmed_commis;
    }
    finances.forEach(finance => {
      if (itemId === finance.user.id && associateLevel === finance.level) {
        confirmed_commis += finance.commission;
      }
    });

    return confirmed_commis;
  }

  let qtt_items = 0;
  let confirmed_commis = 0;
  if (admin) {
    const customers = items.map(item => {
      qtt_items += 1;
      confirmed_commis += confirmedComiss(item.id);
      return {
        ...item,
        visible: false,
        payment_status: paymentStatus(item.id),
      };
    });

    return {
      customers,
      confirmed_commis: formatPrice(confirmed_commis),
      qtt_items,
    };
  }
  const associates = items.map(item => {
    qtt_items += 1;
    confirmed_commis += confirmedComiss(item.user.id, item.level);
    return {
      ...item,
      visible: false,
      payment_status: paymentStatus(item.user.id, item.level),
    };
  });

  return {
    lvl1: associates.filter(associate => associate.level === 1),
    lvl2: associates.filter(associate => associate.level === 2),
    lvl3: associates.filter(associate => associate.level === 3),
    lvl4: associates.filter(associate => associate.level === 4),
    lvl5: associates.filter(associate => associate.level === 5),
    confirmed_commis: formatPrice(confirmed_commis),
    qtt_items,
  };
}
