import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { getPaymentsSuccess, getPaymentsFailure } from './actions';

import { signOut } from '../auth/actions';

import paymentPrepareExcel from '~/util/paymentPrepareExcel';

export function* getPaymentsRequest({ payload }) {
  const { date_ini, date_fin, customers, dateFormatted } = payload;
  try {
    const response = yield call(api.post, 'payments', {
      date_ini,
      date_fin,
    });
    const { data: payments } = response;
    paymentPrepareExcel(payments, customers, dateFormatted);
    yield put(getPaymentsSuccess(payments));
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401)
        toast.error('Token invalido, favor entrar novamente');
      yield put(getPaymentsFailure());
      yield put(signOut());
    } else {
      toast.error('Falha ao buscar dados, o servidor pode estar fora do ar.');
      yield put(getPaymentsFailure());
      yield put(signOut());
    }
  }
}

export default all([
  takeLatest('@payment/GET_PAYMENTS_REQUEST', getPaymentsRequest),
]);
