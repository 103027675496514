import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import addUser from '~/assets/addUser.svg';

import Notifications from '~/components/Notifications';

import logo from '~/assets/logo_wnet.png';
import avatar from '~/assets/avatar.png';

import {
  Container,
  Content,
  Route,
  Indicate,
  Profile,
  HambMenu,
} from './styles';

export default function Header() {
  const profile = useSelector(state => state.user.profile);
  const signed = useSelector(state => state.auth.signed);
  const [links, setLinks] = useState(false);
  const node = useRef();

  const { pathname } = window.location;

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setLinks(false);
  };

  useEffect(() => {
    if (links) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [links]);

  return (
    <Container>
      {signed && (
        <Content>
          <nav>
            <img src={logo} alt="wnet" />
            <Route path={pathname === '/dashboard'}>
              <Link to="/dashboard">DASHBOARD</Link>
            </Route>
            <Route path={pathname === '/finance'}>
              <Link to="/finance">FINANCEIRO</Link>
            </Route>
            <Route path={pathname === '/report'}>
              <Link to="/report">RELATÓRIOS</Link>
            </Route>
            {profile.admin && (
              <>
                <Route path={pathname === '/closure'}>
                  <Link to="/closure">FECHAMENTO</Link>
                </Route>
                <Route path={pathname === '/user'}>
                  <Link to="/user">USUÁRIOS</Link>
                </Route>
              </>
            )}
          </nav>

          <aside>
            <Indicate>
              <img src={addUser} alt="addUser" />
              <Link to="/indicate">Indicar</Link>
            </Indicate>

            <Notifications />

            <Profile path={pathname === '/profile'}>
              <div>
                <strong>{profile.name.toLowerCase().split(' ', 1)}</strong>
                <Link to="/profile">Meu perfil</Link>
              </div>
              <img
                src={profile.avatar ? profile.avatar.url : avatar}
                alt="avatar"
              />
            </Profile>
            <HambMenu
              admin={profile.admin}
              ref={node}
              onClick={() => setLinks(!links)}
            >
              <div />
              <div />
              <div />
              {links && (
                <ul>
                  <div />
                  <li>
                    <Link to="/dashboard">DASHBOARD</Link>
                  </li>
                  <li>
                    <Link to="/finance">FINANCEIRO</Link>
                  </li>
                  <li>
                    <Link to="/report">RELATÓRIOS</Link>
                  </li>
                  {profile.admin && (
                    <>
                      <li>
                        <Link to="/closure">FECHAMENTO</Link>
                      </li>
                      <li>
                        <Link to="/user">USUÁRIOS</Link>
                      </li>
                    </>
                  )}
                </ul>
              )}
            </HambMenu>
          </aside>
        </Content>
      )}
    </Container>
  );
}
