import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { alterIndicatorRequest } from '~/store/modules/user/actions';

import LoadingSpinner from '~/components/LoadingSpinner/index';
import { Container } from './styles';

export default function Closure() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.user.loading);
  const [user_ixc_id, setUserIxcId] = useState('');

  function handleSend() {
    dispatch(alterIndicatorRequest(user_ixc_id));
  }

  function onChangeUserIxcId(event) {
    setUserIxcId(event.target.value);
  }

  return (
    <Container hasValue={!user_ixc_id}>
      {loading && <LoadingSpinner />}
      <h1>Reprocessamento de Usuário</h1>
      <p>Digite abaixo o IXC ID do usuário a ser reprocessado</p>
      <input
        value={user_ixc_id}
        onChange={onChangeUserIxcId}
        placeholder="IXC ID"
      />
      <button
        disabled={!user_ixc_id}
        type="button"
        onClick={() => handleSend()}
      >
        Processar
      </button>
    </Container>
  );
}
