export function updateProfileRequest(data) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: { data },
  };
}

export function updateProfileSucess(profile) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  };
}

export function updateProfileFailure() {
  return {
    type: '@user/UPDATE_PROFILE_FAILURE',
  };
}

export function alterIndicatorRequest(user_ixc_id) {
  return {
    type: '@indicate/ALTER_INDICATOR_REQUEST',
    payload: { user_ixc_id },
  };
}

export function alterIndicatorSuccess() {
  return {
    type: '@indicate/ALTER_INDICATOR_SUCCESS',
  };
}

export function alterIndicatorFailure() {
  return {
    type: '@indicate/ALTER_INDICATOR_FAILURE',
  };
}
