import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '~/components/LoadingSpinner';
import { Container, OtherReport, BtnReport } from './styles';

import FinancialPosition from './financialPosition';
import ReceivedStatement from './receivedStatement';
import ActiveTotal from './activeTotal';
import InactiveTotal from './inactiveTotal';
import PreRegistrationTotal from './preRegistrationTotal';
import DisabledTotal from './disabledTotal';
import DefaulterTotal from './defaulterTotal';

export default function Report() {
  const loadingSpin = useSelector(state => state.associate.loading);
  const [reportTitle, setReportTitle] = useState('Posição financeiro');

  return (
    <Container>
      {loadingSpin && <LoadingSpinner />}
      <OtherReport>
        <BtnReport
          selected={reportTitle === 'Posição financeiro'}
          type="button"
          onClick={() => setReportTitle('Posição financeiro')}
        >
          Posição financeiro
        </BtnReport>
        <div className="other-report">
          <BtnReport
            selected={reportTitle === 'Extrato de recebidos'}
            type="button"
            onClick={() => setReportTitle('Extrato de recebidos')}
          >
            Extrato de recebidos
          </BtnReport>
          <BtnReport
            selected={reportTitle === 'Total ativo'}
            type="button"
            onClick={() => setReportTitle('Total ativo')}
          >
            Total ativo
          </BtnReport>
          <BtnReport
            selected={reportTitle === 'Total inativo'}
            type="button"
            onClick={() => setReportTitle('Total inativo')}
          >
            Total inativo (aguardando pagamento)
          </BtnReport>
        </div>
        <div className="other-report">
          <BtnReport
            selected={reportTitle === 'Total pré cadastro'}
            type="button"
            onClick={() => setReportTitle('Total pré cadastro')}
          >
            Total pré cadastro
          </BtnReport>
          <BtnReport
            selected={reportTitle === 'Total desativado'}
            type="button"
            onClick={() => setReportTitle('Total desativado')}
          >
            Total desativado
          </BtnReport>
          <BtnReport
            selected={reportTitle === 'Total inadimplente'}
            type="button"
            onClick={() => setReportTitle('Total inadimplente')}
          >
            Total inadimplente (&gt;30 dias)
          </BtnReport>
        </div>
      </OtherReport>
      {reportTitle === 'Posição financeiro' && <FinancialPosition />}
      {reportTitle === 'Extrato de recebidos' && <ReceivedStatement />}
      {reportTitle === 'Total ativo' && <ActiveTotal />}
      {reportTitle === 'Total inativo' && <InactiveTotal />}
      {reportTitle === 'Total pré cadastro' && <PreRegistrationTotal />}
      {reportTitle === 'Total desativado' && <DisabledTotal />}
      {reportTitle === 'Total inadimplente' && <DefaulterTotal />}
    </Container>
  );
}
