import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  background: #1f7ecc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Panel = styled.div`
  min-width: ${props => (props.path ? '' : '500px')};
  background: ${props => (props.path ? 'none' : '#fff')};

  border-radius: 10px;
  display: flex;

  img {
    height: 180px;

    padding-top: 10px;
  }

  @media only screen and (max-width: 750px) {
    min-width: 90%;
  }
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  padding: ${props => (props.path ? '0' : '40px')};

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 90%;

    input {
      background: #fff;
      border: 0;
      border-bottom: 1px solid #999;
      height: 44px;
      padding: 0 15px;
      color: #1f7ecc;
      margin: 0 0 10px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    button {
      margin: 60px 0 0;
      height: 44px;
      background: #1f7ecc;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 30px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.1, '#1f7ecc')};
      }
    }
  }

  a {
    color: #999;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
`;
