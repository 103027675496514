import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';

import { formatPrice } from '~/util/format';

export default function PrintExcel(admin, associates, model) {
  const blobType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const workbook = new Excel.Workbook();
  workbook.creator = 'WNET';
  workbook.created = new Date();
  const worksheet = workbook.addWorksheet(model);
  if (model === 'Posição financeiro') {
    if (admin) {
      worksheet.columns = [
        { header: 'Associado', key: 'associate', width: 30 },
        {
          header: 'Comissão esperada',
          key: 'total_commis_expected',
          width: 20,
        },
        {
          header: 'Comissão a confirmar',
          key: 'total_commission_to_confirm',
          width: 20,
        },
        { header: 'Comissão confirmada', key: 'total_commission', width: 20 },
      ];
      for (let i = 0; i < associates.length; i += 1) {
        worksheet.addRow({
          associate: associates[i].user.name,
          total_commis_expected: associates[i].user.total_commis_expected,
          total_commission_to_confirm:
            associates[i].user.total_commis_to_confirm,
          total_commission: associates[i].user.total_commission,
        });
      }
      worksheet.getCell('A1').style.font = {
        bold: true,
      };
      worksheet.getCell('A1').border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('B1').style.font = {
        bold: true,
        color: { argb: '1f7ecc' },
      };
      worksheet.getCell('B1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('C1').style.font = {
        bold: true,
        color: { argb: 'ff0000' },
      };
      worksheet.getCell('C1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('D1').style.font = {
        bold: true,
        color: { argb: '00d237' },
      };
      worksheet.getCell('D1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };
    }
  }
  if (
    model === 'Extrato de recebidos' ||
    model === 'Total desativado' ||
    model === 'Posição financeiro'
  ) {
    if (admin) {
      worksheet.columns = [
        { header: 'Nome', key: 'name', width: 30 },
        { header: 'Indicado', key: 'indicate', width: 30 },
        {
          header: `${
            model === 'Total desativado'
              ? 'Comissão a confirmar'
              : 'Comissão confirmada'
          }`,
          key: 'value',
          width: 25,
        },
      ];
      for (let i = 0; i < associates.length; i += 1) {
        for (let j = 0; j < associates[i].son_finances.length; j += 1) {
          worksheet.addRow({
            name: associates[i].user.name,
            indicate: associates[i].son_finances[j].name,
            value:
              model === 'Total desativado'
                ? associates[i].son_finances[j].total_commis_to_confirm
                : associates[i].son_finances[j].total_commission,
          });
        }
      }
      worksheet.getCell('A1').style.font = {
        bold: true,
      };
      worksheet.getCell('A1').border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('B1').style.font = {
        bold: true,
      };
      worksheet.getCell('B1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('C1').style.font = {
        bold: true,
      };
      worksheet.getCell('C1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };
    } else {
      worksheet.columns = [
        { header: 'Nº Título', key: 'ixc_id', width: 10 },
        { header: 'Nome', key: 'name', width: 30 },
        { header: 'Nível', key: 'level', width: 7 },
        { header: 'Data de vencimento', key: 'formated_due_date', width: 20 },
        {
          header: 'Data de pagamento',
          key: 'formated_payment_date',
          width: 20,
        },
        { header: 'Valor', key: 'formated_value', width: 12 },
        { header: 'Valor pago', key: 'formated_payment_value', width: 12 },
        { header: 'Comissão', key: 'formated_commission', width: 12 },
      ];
      for (let i = 0; i < associates.length; i += 1) {
        for (let j = 0; j < associates[i].finances.length; j += 1) {
          worksheet.addRow({
            ixc_id: associates[i].finances[j].ixc_id,
            name: associates[i].user.name,
            level: associates[i].finances[j].level,
            formated_due_date: associates[i].finances[j].formated_due_date,
            formated_payment_date:
              associates[i].finances[j].formated_payment_date,
            formated_value: associates[i].finances[j].formated_value,
            formated_payment_value:
              associates[i].finances[j].formated_payment_value,
            formated_commission: associates[i].finances[j].formated_commission,
          });
        }
      }
      worksheet.getCell('A1').style.font = {
        bold: true,
      };
      worksheet.getCell('A1').border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('B1').style.font = {
        bold: true,
      };
      worksheet.getCell('B1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('C1').style.font = {
        bold: true,
      };
      worksheet.getCell('C1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('D1').style.font = {
        bold: true,
      };
      worksheet.getCell('D1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('E1').style.font = {
        bold: true,
      };
      worksheet.getCell('E1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('F1').style.font = {
        bold: true,
      };
      worksheet.getCell('F1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('G1').style.font = {
        bold: true,
      };
      worksheet.getCell('G1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };
    }
  }
  if (
    model === 'Total ativo' ||
    model === 'Total inativo' ||
    model === 'Total inadimplente'
  ) {
    if (admin) {
      worksheet.columns = [
        { header: 'Associado', key: 'name', width: 40 },
        { header: 'Ativo', key: 'active', width: 6 },
        { header: 'Administrador', key: 'admin', width: 14 },
        { header: 'Comissão esperada', key: 'commis_expected', width: 18 },
        { header: 'E-mail', key: 'email', width: 30 },
        {
          header: `${
            model === 'Total inativo' || model === 'Total inadimplente'
              ? 'Comissão a confirmar'
              : 'Comissão confirmada'
          }`,
          key: 'value',
          width: 20,
        },
      ];
      for (let i = 0; i < associates.length; i += 1) {
        worksheet.addRow({
          name: associates[i].user.name,
          active: associates[i].user.active ? 'Sim' : 'Não',
          admin: associates[i].user.admin ? 'Sim' : 'Não',
          commis_expected: associates[i].user.total_commis_expected,
          email: associates[i].user.email,
          value:
            model === 'Total inativo' || model === 'Total inadimplente'
              ? formatPrice(
                  Number(
                    associates[i].user.total_commis_to_confirm.replace(',', '.')
                  )
                )
              : formatPrice(
                  Number(associates[i].user.total_commission.replace(',', '.'))
                ),
        });
      }
      worksheet.getCell('A1').style.font = {
        bold: true,
      };
      worksheet.getCell('A1').border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('B1').style.font = {
        bold: true,
      };
      worksheet.getCell('B1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('C1').style.font = {
        bold: true,
      };
      worksheet.getCell('C1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('D1').style.font = {
        bold: true,
      };
      worksheet.getCell('D1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('E1').style.font = {
        bold: true,
      };
      worksheet.getCell('E1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('F1').style.font = {
        bold: true,
      };
      worksheet.getCell('F1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };
    } else {
      worksheet.columns = [
        { header: 'Associado', key: 'name', width: 40 },
        { header: 'Id Indicator', key: 'indicator', width: 11 },
        { header: 'Contrato', key: 'contract', width: 10 },
        { header: 'Comissão esperada', key: 'commis_expected', width: 18 },
        { header: 'E-mail', key: 'email', width: 30 },
        {
          header: `${
            model === 'Total inativo' || model === 'Total inadimplente'
              ? 'Comissão a confirmar'
              : 'Comissão confirmada'
          }`,
          key: 'value',
          width: 20,
        },
      ];
      for (let i = 0; i < associates.length; i += 1) {
        worksheet.addRow({
          name: associates[i].user.name,
          indicator: associates[i].user.indicator,
          contract: associates[i].user.contract,
          commis_expected: associates[i].user.total_commis_expected,
          email: associates[i].user.email,
          value:
            model === 'Total inativo' || model === 'Total inadimplente'
              ? formatPrice(
                  Number(
                    associates[i].user.total_commis_to_confirm.replace(',', '.')
                  )
                )
              : formatPrice(
                  Number(associates[i].user.total_commission.replace(',', '.'))
                ),
        });
      }
      worksheet.getCell('A1').style.font = {
        bold: true,
      };
      worksheet.getCell('A1').border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('B1').style.font = {
        bold: true,
      };
      worksheet.getCell('B1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('C1').style.font = {
        bold: true,
      };
      worksheet.getCell('C1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('D1').style.font = {
        bold: true,
      };
      worksheet.getCell('D1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('E1').style.font = {
        bold: true,
      };
      worksheet.getCell('E1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' },
      };
      worksheet.getCell('F1').style.font = {
        bold: true,
      };
      worksheet.getCell('F1').border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };
    }
  }
  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], { type: blobType });
    FileSaver.saveAs(blob, model);
  });
}
