import styled from 'styled-components';

export const Container = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300&display=swap');

  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1050;
  align-items: center;
  justify-content: center;
  color: #fff;

  h1 {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;
  }

  p {
    font-size: 36px;
    font-weight: 100;
    text-align: center;
    margin-bottom: 15px;
  }

  input {
    margin-bottom: 15px;
    border-radius: 4px;
    border: none;
    text-align: center;
    font-size: 20px;
  }

  button {
    font-size: 24px;
    color: #1f7ecc;
    background: #fff;
    width: 270px;
    height: 60px;
    border: none;
    border-radius: 30px;
    cursor: ${props => (props.hasValue ? 'not-allowed' : 'pointer')};
  }
`;
