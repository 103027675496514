import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  format,
  subMonths,
  addMonths,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import pt from 'date-fns/locale/pt';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import {
  getFinancesRequest,
  getCustomersRequest,
} from '~/store/modules/associate/actions';

import formatFinances from './formatFinances';

import { perPage } from '~/util/constantsVariables';
import LoadingSpinner from '~/components/LoadingSpinner/index';
import {
  Container,
  RowPanel,
  Panel,
  AssociateList,
  Associate,
  AssociateInfo,
  RowInfo,
  PageActions,
} from './styles';

import avatar from '~/assets/avatar.png';

export default function Finance() {
  const dispatch = useDispatch();
  const prevFinances = useSelector(state => state.associate.finances);
  const prevCustomers = useSelector(state => state.associate.customers);
  const loading = useSelector(state => state.associate.loading);
  const user = useSelector(state => state.user.profile);
  const [date, setDate] = useState(new Date());
  const [finances, setFinances] = useState([]);
  const [expectedCommission, setExpectedCommission] = useState('R$ 0,00');
  const [confirmedCommission, setConfirmedCommission] = useState('R$ 0,00');
  const [commissionToConfirm, setCommissionToConfirm] = useState('R$ 0,00');
  const [page, setPage] = useState(0);
  const [financesPerPage, setFinancesPerPage] = useState([]);

  const dateFormatted = useMemo(
    () => format(date, "MMMM 'de' yyyy", { locale: pt }),
    [date]
  );

  function handlePrevMonth() {
    setDate(subMonths(date, 1));
  }

  function handleNextMonth() {
    setDate(addMonths(date, 1));
  }

  function handlePage(action) {
    setPage(action === 'back' ? page - 1 : page + 1);
  }

  useEffect(() => {
    setFinancesPerPage(
      finances.slice(page * perPage, page * perPage + perPage)
    );
  }, [finances, page]);

  useEffect(() => {
    function loadTitles() {
      const start = format(startOfMonth(date), "yyyy'-'MM'-'dd", {
        locale: pt,
      });
      const end = format(endOfMonth(date), "yyyy'-'MM'-'dd", {
        locale: pt,
      });

      dispatch(getFinancesRequest(start, end));
    }

    loadTitles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (prevFinances.length > 0) {
      if (user.admin && prevCustomers.length > 0) {
        const {
          auxCustomers,
          commis_expected,
          commis_to_confirm,
          commission,
        } = formatFinances(true, prevFinances, prevCustomers);

        setFinances(auxCustomers);
        setExpectedCommission(commis_expected);
        setCommissionToConfirm(commis_to_confirm);
        setConfirmedCommission(commission);
      }
      if (!user.admin) {
        const {
          auxFinances,
          commis_expected,
          commis_to_confirm,
          commission,
        } = formatFinances(false, prevFinances, prevCustomers);

        setFinances(auxFinances);
        setExpectedCommission(commis_expected);
        setCommissionToConfirm(commis_to_confirm);
        setConfirmedCommission(commission);
      }
    } else {
      setFinances([]);
      setExpectedCommission('R$ 0,00');
      setConfirmedCommission('R$ 0,00');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevFinances, prevCustomers]);

  useEffect(() => {
    function loadCustomers() {
      dispatch(getCustomersRequest());
    }

    function loadFinances() {
      const currentDate = new Date();
      const start = format(startOfMonth(currentDate), "yyyy'-'MM'-'dd", {
        locale: pt,
      });
      const end = format(endOfMonth(currentDate), "yyyy'-'MM'-'dd", {
        locale: pt,
      });

      dispatch(getFinancesRequest(start, end));
    }

    if (user.admin) {
      loadCustomers();
    }
    loadFinances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {loading && <LoadingSpinner />}
      <RowPanel>
        <Panel color={1}>
          <div />
          <div>
            <strong>{expectedCommission}</strong>
            <p>Comissão esperada</p>
          </div>
        </Panel>
        <Panel color={2}>
          <div />
          <div>
            <strong>{commissionToConfirm}</strong>
            <p>Comissão a confirmar</p>
          </div>
        </Panel>
        <Panel color={3}>
          <div />
          <div>
            <strong>{confirmedCommission}</strong>
            <p>Comissão confirmada</p>
          </div>
        </Panel>
      </RowPanel>
      <header>
        <button type="button" onClick={handlePrevMonth}>
          <MdChevronLeft size={36} color="#FFF" />
        </button>
        <strong>{dateFormatted}</strong>
        <button type="button" onClick={handleNextMonth}>
          <MdChevronRight size={36} color="#FFF" />
        </button>
      </header>
      <PageActions>
        <button
          type="button"
          onClick={() => handlePage('back')}
          disabled={page < 1}
        >
          <MdChevronLeft size={20} />
          Voltar
        </button>
        <button
          type="button"
          onClick={() => handlePage('next')}
          disabled={finances.length - perPage * (page + 1) <= 0}
        >
          Próximo
          <MdChevronRight size={20} />
        </button>
      </PageActions>
      <AssociateList>
        {!(financesPerPage.length > 0) && <h1>Sem titulos</h1>}
        {user.admin && financesPerPage.length > 0
          ? financesPerPage.map(finance => (
              <li key={finance.id}>
                <Associate Status={finance.total_commission !== 'R$ 0,00'}>
                  <img
                    src={finance.avatar ? finance.avatar.url : avatar}
                    alt="avatar"
                  />
                  <AssociateInfo capitalize>
                    <strong>{finance.name.toLowerCase()}</strong>
                    <p>{finance.active ? 'Ativo' : 'Não Ativo'}</p>
                  </AssociateInfo>
                </Associate>
                <RowInfo flex={user.admin}>
                  <AssociateInfo>
                    <strong>Comissão esperada</strong>
                    <p>{finance.total_commis_expected}</p>
                  </AssociateInfo>
                  <AssociateInfo>
                    <strong>Comissão a confirmar</strong>
                    <p>{finance.total_commis_to_confirm}</p>
                  </AssociateInfo>
                  <AssociateInfo>
                    <strong>Comissão confirmada</strong>
                    <p>{finance.total_commission}</p>
                  </AssociateInfo>
                </RowInfo>
              </li>
            ))
          : financesPerPage.length > 0 &&
            financesPerPage.map(finance => (
              <li key={finance.id}>
                <Associate Status={finance.payment_value}>
                  <img
                    src={finance.user.avatar ? finance.user.avatar.url : avatar}
                    alt="avatar"
                  />
                  <AssociateInfo capitalize>
                    <strong>{finance.user.name.toLowerCase()}</strong>
                    <p>
                      {finance.level}º Nível - {finance.percent}%
                    </p>
                  </AssociateInfo>
                </Associate>
                <RowInfo flex={user.admin}>
                  <AssociateInfo>
                    <strong>Vencimento em</strong>
                    <p>
                      {finance.formated_due_date
                        ? finance.formated_due_date
                        : ' / / '}
                    </p>
                  </AssociateInfo>
                  <AssociateInfo>
                    <strong>Pagamento em</strong>
                    <p>
                      {finance.formated_payment_date
                        ? finance.formated_payment_date
                        : ' / / '}
                    </p>
                  </AssociateInfo>
                </RowInfo>
                <RowInfo flex={user.admin}>
                  <AssociateInfo>
                    <strong>Valor</strong>
                    <p>{finance.formated_value}</p>
                  </AssociateInfo>
                  <AssociateInfo>
                    <strong>Valor pago</strong>
                    <p>{finance.formated_payment_value}</p>
                  </AssociateInfo>
                  <AssociateInfo>
                    <strong>Comissão</strong>
                    <p>{finance.formated_commission}</p>
                  </AssociateInfo>
                </RowInfo>
              </li>
            ))}
      </AssociateList>
    </Container>
  );
}
