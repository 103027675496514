import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1050px;
  width: 90%;
  margin: 30px;
  padding: 20px;

  background: #fff;
  border-radius: 6px;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .reportTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);
      padding: 5px 0;
      margin: 10px 0;

      h1 {
        color: rgba(0, 0, 0, 0.7);
      }

      .printButtons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          height: 20px;
        }

        a {
          text-decoration: none;
          margin: 5px;
          background-color: transparent;
          border: none;
        }

        button {
          background-color: transparent;
          border: none;
          margin: 5px;
        }
      }
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 10px 0;
      padding: 10px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);

      h2:first-child {
        color: rgba(0, 0, 0, 0.7);
      }

      nav {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        @media only screen and (max-width: 750px) {
          flex-direction: column;
        }
      }
    }

    form {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      margin: 15px 0;

      @media only screen and (max-width: 750px) {
        justify-content: center;
        flex-direction: column;
      }

      div {
        width: 95%;
        margin: 10px 0;
        p {
          width: 95%;
          color: rgba(0, 0, 0, 0.7);
        }

        input {
          width: 95%;
          height: 25px;
          border: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.7);
          padding-left: 5px;

          option {
            text-transform: capitalize;
          }
        }
      }
      button {
        width: 50%;
        border: none;
        background: #1f7ecc;
        color: #fff;
        padding: 5px;
        border-radius: 4px;
      }
    }
  }
`;

export const OtherReport = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .other-report {
    display: flex;
    flex-direction: row;
  }
`;

export const BtnReport = styled.button`
  flex: 1;
  margin: 4px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.1);
  border: ${props => (props.selected ? '0.5px solid #1f7ecc' : 'none')};
  border-radius: 4px;
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
`;

export const Filter = styled.form``;

export const Total = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 20px;

  > p {
    margin: 0 5px 5px 0;
  }

  h2 {
    font-size: 32px;
    color: ${props => props.color};
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 150px;
  }
`;

export const AssociateList = styled.ul`
  width: 100%;

  li {
    margin-bottom: 20px;
    h1 {
      color: rgba(0, 0, 0, 0.7);
      text-transform: capitalize;
    }

    .associate-total {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      @media only screen and (max-width: 750px) {
        flex-direction: column;
      }
    }

    .overflow-table {
      overflow-x: auto;
      width: 100.2%;

      table {
        width: 100%;
        tr {
          th {
            min-width: 100px;
            padding: 5px 0;
          }
          td {
            min-width: 100px;
            background: #eee;
            padding: 5px;
            text-align: center;
          }
        }
      }
    }
  }
`;

export const AssociateInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 75px;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }

  img {
    width: 64px;
    height: 64px;
    margin-right: 10px;
    border-radius: 32px;
  }
  h2 {
    font-size: 24px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.7);
    flex: 1;
  }

  .associate-separator {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h3 {
      font-size: 14px;
      text-transform: capitalize;
      color: rgba(0, 0, 0, 0.7);
      margin-right: 5px;
      padding: 3px 0;
    }
  }
`;

export const AssociateTotal = styled.div`
  display: flex;
  width: 100%;
  height: 75px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 10px 0;
  background: #eee;
  border: 2px solid #ddd;

  > p {
    margin: 0 5px 5px 0;
  }

  h2 {
    font-size: 28px;
    color: ${props => props.color};
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 150px;
  }
`;

export const PageActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    color: #574b95;
    background: transparent;
    padding: 10px 14px;
    border-radius: 4px;
    transition: 1s;
    &:hover {
      color: #b009f1;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
