import React from 'react';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';

import avatar from '~/assets/avatar.png';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    display: 'flex',
    width: '100%',
    flex: 1,
    paddingLeft: 20,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    backgroundColor: '#eee',
    borderWidth: 1,
    borderColor: '#ddd',
    marginTop: 10,
    padding: 5,
  },
  total: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  totalLabel: {
    fontSize: 16,
    color: '#222',
    width: 100,
  },
  rs: {
    margin: 5,
    fontSize: 10,
    color: '#222',
  },
  totalSeparator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: 110,
  },
  commisLabel: {
    fontSize: 10,
    color: '#222',
  },
  expectedCommission: {
    fontSize: 28,
    color: '#1F7ECC',
  },
  commissionToConfirm: {
    fontSize: 28,
    color: '#f00',
  },
  confirmedCommission: {
    fontSize: 28,
    color: '#00D237',
  },
  associateUl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  associateLi: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: 10,
  },
  associateInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  associateName: {
    textAlign: 'left',
    paddingLeft: 20,
    fontSize: 18,
    flex: 1,
    height: 22,
    color: '#222',
    textTransform: 'capitalize',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
  },
  tHead: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  tHeadText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#222',
    flex: 1,
    padding: 5,
  },
  tHeadText2: {
    textAlign: 'center',
    fontSize: 12,
    color: '#222',
    width: '50%',
    padding: 5,
  },
  tBody: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  tBodyText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#222',
    flex: 1,
    backgroundColor: '#eee',
    borderWidth: 1,
    borderColor: '#fff',
    padding: 5,
  },
  tBodyText2: {
    textAlign: 'center',
    fontSize: 12,
    color: '#222',
    width: '50%',
    backgroundColor: '#eee',
    borderWidth: 1,
    borderColor: '#fff',
    padding: 5,
  },
  associateView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  associateAvatar: {
    width: 64,
    height: 64,
    marginRight: 10,
  },
  associateData: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  dataSeparator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  },
  dataText: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
});

export default function PrintPDF({
  admin,
  associates,
  expectedCommission,
  commissionToConfirm,
  confirmedCommission,
  model,
}) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.nav}>
            <Text style={styles.totalLabel}>Totais gerais: </Text>
            {expectedCommission && (
              <View style={styles.total}>
                <Text style={styles.rs}>R$</Text>
                <View style={styles.totalSeparator}>
                  <Text style={styles.commisLabel}>Comissão esperada</Text>
                  <Text style={styles.expectedCommission}>
                    {expectedCommission}
                  </Text>
                </View>
              </View>
            )}
            {commissionToConfirm && (
              <View style={styles.total}>
                <Text style={styles.rs}>R$</Text>
                <View style={styles.totalSeparator}>
                  <Text style={styles.commisLabel}>Comissão a confirmar</Text>
                  <Text style={styles.commissionToConfirm}>
                    {commissionToConfirm}
                  </Text>
                </View>
              </View>
            )}
            {confirmedCommission && (
              <View style={styles.total}>
                <Text style={styles.rs}>R$</Text>
                <View style={styles.totalSeparator}>
                  <Text style={styles.commisLabel}>Comissão confirmada</Text>
                  <Text style={styles.confirmedCommission}>
                    {confirmedCommission}
                  </Text>
                </View>
              </View>
            )}
          </View>
          <View style={styles.associateUl}>
            {associates.map(associate => (
              <View style={styles.associateLi} key={Math.random() * 10}>
                <View style={styles.associateInfo} wrap={false}>
                  {model !== 'Total ativo' &&
                    model !== 'Total inativo' &&
                    model !== 'Total inadimplente' && (
                      <Text style={styles.associateName}>
                        Associado: {associate.user.name.toLowerCase()}
                      </Text>
                    )}
                  <View style={styles.nav}>
                    {model === 'Total ativo' ||
                    model === 'Total inativo' ||
                    model === 'Total inadimplente' ? (
                      <View style={styles.associateView}>
                        <Image
                          style={styles.associateAvatar}
                          src={
                            associate.user.avatar
                              ? associate.user.avatar.url
                              : avatar
                          }
                        />
                        <Text style={styles.associateName}>
                          {associate.user.name.toLowerCase()}
                        </Text>
                      </View>
                    ) : (
                      <Text style={styles.totalLabel}>Totais:</Text>
                    )}
                    {expectedCommission && (
                      <View style={styles.total}>
                        <Text style={styles.rs}>R$</Text>
                        <View style={styles.totalSeparator}>
                          <Text style={styles.commisLabel}>
                            Comissão esperada
                          </Text>
                          <Text style={styles.expectedCommission}>
                            {associate.user.total_commis_expected}
                          </Text>
                        </View>
                      </View>
                    )}
                    {commissionToConfirm && (
                      <View style={styles.total}>
                        <Text style={styles.rs}>R$</Text>
                        <View style={styles.totalSeparator}>
                          <Text style={styles.commisLabel}>
                            Comissão a confirmar
                          </Text>
                          <Text style={styles.commissionToConfirm}>
                            {associate.user.total_commis_to_confirm}
                          </Text>
                        </View>
                      </View>
                    )}
                    {confirmedCommission && (
                      <View style={styles.total}>
                        <Text style={styles.rs}>R$</Text>
                        <View style={styles.totalSeparator}>
                          <Text style={styles.commisLabel}>
                            Comissão confirmada
                          </Text>
                          <Text style={styles.confirmedCommission}>
                            {associate.user.total_commission}
                          </Text>
                        </View>
                      </View>
                    )}
                  </View>
                </View>
                {model === 'Total ativo' ||
                model === 'Total inativo' ||
                model === 'Total inadimplente' ? (
                  <View style={styles.associateData}>
                    {admin ? (
                      <View style={styles.dataSeparator}>
                        <Text style={styles.dataText}>
                          Ativo: {associate.user.active ? ' Sim' : ' Não'}
                        </Text>
                        <Text style={styles.dataText}>
                          Administrador:{' '}
                          {associate.user.admin ? ' Sim' : ' Não'}
                        </Text>
                      </View>
                    ) : (
                      <View style={styles.dataSeparator}>
                        <Text style={styles.dataText}>
                          Id Indicador: {associate.user.indicator}
                        </Text>
                        <Text style={styles.dataText}>
                          Contrato: {associate.user.contract}
                        </Text>
                      </View>
                    )}
                    <View style={styles.dataSeparator}>
                      <Text style={styles.dataText}>
                        Comissão esperada:{' '}
                        {associate.user.total_commis_expected}
                      </Text>
                      <Text style={styles.dataText}>
                        Email: {associate.user.email}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View style={styles.table}>
                    {admin ? (
                      <View style={styles.tHead} wrap={false}>
                        <Text style={styles.tHeadText2}>Nome</Text>
                        <Text style={styles.tHeadText2}>
                          {model === 'Extrato de recebidos'
                            ? 'Comissão confirmada'
                            : 'Comissão a confirmar'}
                        </Text>
                      </View>
                    ) : (
                      <View style={styles.tHead} wrap={false}>
                        <Text style={styles.tHeadText}>Nº Título</Text>
                        <Text style={styles.tHeadText}>Nivel</Text>
                        <Text style={styles.tHeadText}>Vencimento</Text>
                        <Text style={styles.tHeadText}>Pagamento em</Text>
                        <Text style={styles.tHeadText}>Valor</Text>
                        <Text style={styles.tHeadText}>Valor pago</Text>
                        <Text style={styles.tHeadText}>Comissão</Text>
                      </View>
                    )}
                    {admin
                      ? associate.son_finances.map(finance => (
                          <View
                            style={styles.tBody}
                            key={finance.id}
                            wrap={false}
                          >
                            <Text style={styles.tBodyText2}>
                              {finance.name}
                            </Text>
                            <Text style={styles.tBodyText2}>
                              {model === 'Extrato de recebidos'
                                ? finance.total_commission
                                : finance.total_commis_to_confirm}
                            </Text>
                          </View>
                        ))
                      : associate.finances.map(finance => (
                          <View
                            style={styles.tBody}
                            key={finance.id}
                            wrap={false}
                          >
                            <Text style={styles.tBodyText}>
                              {finance.ixc_id}
                            </Text>
                            <Text style={styles.tBodyText}>
                              {finance.level}
                            </Text>
                            <Text style={styles.tBodyText}>
                              {finance.level}
                            </Text>
                            <Text style={styles.tBodyText}>
                              {finance.formated_due_date}
                            </Text>
                            <Text style={styles.tBodyText}>
                              {finance.formated_payment_date}
                            </Text>
                            <Text style={styles.tBodyText}>
                              {finance.formated_value}
                            </Text>
                            <Text style={styles.tBodyText}>
                              {finance.formated_payment_value}
                            </Text>
                            <Text style={styles.tBodyText}>
                              {finance.formated_commission}
                            </Text>
                          </View>
                        ))}
                  </View>
                )}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
}
