import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import {
  getFinancesRequest,
  getCustomersRequest,
} from '~/store/modules/associate/actions';

import PrintPDF from '~/util/printPDF';
import PrintExcel from '~/util/printExcel';
import formatFinances from './formatFinances';

import pdf from '~/assets/pdf.svg';
import pdfOk from '~/assets/pdfOk.svg';
import excel from '~/assets/excel.svg';

import { Total, AssociateList, AssociateTotal, PageActions } from './styles';
import { perPage } from '~/util/constantsVariables';

export default function InactiveTotal() {
  const model = 'Total desativado';
  const dispatch = useDispatch();
  const finances = useSelector(state => state.associate.finances);
  const customers = useSelector(state => state.associate.customers);
  const user = useSelector(state => state.user.profile);

  const [generated, setGenerated] = useState(false);

  const [associates, setAssociates] = useState([]);
  const [associatesHolder, setAssociatesHolder] = useState([]);

  const [startDateErro, setStartDateErro] = useState('');
  const [endDateErro, setEndDateErro] = useState('');
  const [currentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    format(startOfMonth(currentDate), "yyyy'-'MM'-'dd", {
      locale: pt,
    })
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(currentDate), "yyyy'-'MM'-'dd", {
      locale: pt,
    })
  );

  const [commissionToConfirm, setCommissionToConfirm] = useState('0,00');

  const [page, setPage] = useState(0);
  const [associatesPerPage, setAssociatesPerPage] = useState([]);

  function handlePage(action) {
    setPage(action === 'back' ? page - 1 : page + 1);
  }

  useEffect(() => {
    setAssociatesPerPage(
      associates.slice(page * perPage, page * perPage + perPage)
    );
  }, [associates, page]);

  useEffect(() => {
    function loadCustomers() {
      dispatch(getCustomersRequest());
    }

    function loadTitles() {
      const start = format(startOfMonth(currentDate), "yyyy'-'MM'-'dd", {
        locale: pt,
      });
      const end = format(endOfMonth(currentDate), "yyyy'-'MM'-'dd", {
        locale: pt,
      });

      dispatch(getFinancesRequest(start, end));
    }

    if (user.admin) {
      loadCustomers();
    }
    loadTitles();
  }, [currentDate, dispatch, user]);

  useEffect(() => {
    if (finances.length > 0 && user) {
      if (user.admin && customers.length > 0) {
        const auxCustomers = formatFinances(true, finances, model, customers);
        setCommissionToConfirm(auxCustomers.total_commis_to_confirm);

        setAssociates(auxCustomers.customers);
        setAssociatesHolder(auxCustomers.customers);
      } else if (!user.admin) {
        const auxAssociates = formatFinances(false, finances, model);
        setCommissionToConfirm(auxAssociates.total_commis_to_confirm);

        setAssociates(auxAssociates.associates);
        setAssociatesHolder(auxAssociates.associates);
      }
    } else {
      setCommissionToConfirm('0,00');
      setAssociates([]);
      setAssociatesHolder([]);
    }
  }, [customers, finances, user]);

  function handleFilterDate(e) {
    e.preventDefault();
    setGenerated(false);
    if (isNaN(startDate)) {
      setStartDateErro('');
      if (isNaN(endDate)) {
        setEndDateErro('');
        if (startDate <= endDate) {
          dispatch(getFinancesRequest(startDate, endDate));
        } else {
          setEndDateErro('Data final deve ser maior');
          setStartDateErro('Data inicial deve ser menor');
        }
      } else {
        setEndDateErro('Digite uma data válida');
      }
    } else {
      setStartDateErro('Digite uma data válida');
    }
  }

  function handleFilterAssociate(text) {
    setGenerated(false);
    const newAssociates = associatesHolder.filter(associate => {
      const itemData = associate.user.name.toLowerCase().replace(/\s\s+/g, ' ');

      const textData = text.toLowerCase().replace(/\s\s+/g, ' ');

      return itemData.indexOf(textData) === 0;
    });

    setAssociates(newAssociates);
  }

  function renderTable(associate) {
    if (user.admin && associate.son_finances.length > 0) {
      return (
        <table>
          <thead>
            <tr align="center">
              <th>Nº Título</th>
              <th>Nome</th>
              <th>Comissão a confirmar</th>
            </tr>
          </thead>
          <tbody>
            {associate.son_finances.map(finance => (
              <tr key={finance.id}>
                <td>{finance.ixc_id}</td>
                <td>{finance.name}</td>
                <td>{finance.total_commis_to_confirm}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    if (!user.admin && associate.finances.length > 0) {
      return (
        <table>
          <thead>
            <tr align="center">
              <th>Nº Título</th>
              <th>Nível</th>
              <th>Vencimento em</th>
              <th>Pagamento em</th>
              <th>Valor</th>
              <th>Valor pago</th>
              <th>Comissão</th>
            </tr>
          </thead>
          <tbody>
            {associate.finances.map(finance => (
              <tr key={finance.id}>
                <td>{finance.ixc_id}</td>
                <td>{finance.level}</td>
                <td>{finance.formated_due_date}</td>
                <td>{finance.formated_payment_date}</td>
                <td>{finance.formated_value}</td>
                <td>{finance.formated_payment_value}</td>
                <td>{finance.formated_commission}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return <p>Nenhum titulo</p>;
  }

  return (
    <>
      <header>
        <div className="reportTitle">
          <h1>{model}</h1>
          {associates.length > 0 && (
            <div className="printButtons">
              <h4>Exportar em: </h4>
              {generated ? (
                <PDFDownloadLink
                  document={
                    <PrintPDF
                      admin={user.admin}
                      associates={associates}
                      commissionToConfirm={commissionToConfirm}
                      model={model}
                    />
                  }
                  fileName={`${model}.pdf`}
                >
                  {({ loading }) =>
                    loading ? 'Carregando...' : <img src={pdfOk} alt="pdfOk" />
                  }
                </PDFDownloadLink>
              ) : (
                <button type="button" onClick={() => setGenerated(true)}>
                  <img src={pdf} alt="pdf" />
                </button>
              )}
              <button
                type="button"
                onClick={() => PrintExcel(user.admin, associates, model)}
              >
                <img src={excel} alt="excel" />
              </button>
            </div>
          )}
        </div>
        <form onSubmit={e => handleFilterDate(e)}>
          <div>
            <p>Digite nome para busca:</p>
            <input
              onChange={event => handleFilterAssociate(event.target.value)}
              type="text"
              placeholder="..."
            />
          </div>
          <div>
            <p>De:</p>
            <input
              value={startDate}
              type="date"
              onChange={event => setStartDate(event.target.value)}
            />
            {startDateErro && <span>{startDateErro}</span>}
          </div>
          <div>
            <p>Até:</p>
            <input
              value={endDate}
              type="date"
              onChange={event => setEndDate(event.target.value)}
            />
            {endDateErro && <span>{endDateErro}</span>}
          </div>
          <button type="submit">Aplicar Data</button>
        </form>
        <div className="header">
          <h2>Totais</h2>
          <nav>
            <Total color="#f00">
              <p>R$</p>
              <div>
                <p>Comissão a confirmar</p>
                <h2>{commissionToConfirm}</h2>
              </div>
            </Total>
          </nav>
        </div>
      </header>
      <PageActions>
        <button
          type="button"
          onClick={() => handlePage('back')}
          disabled={page < 1}
        >
          <MdChevronLeft size={20} />
          Voltar
        </button>
        <button
          type="button"
          onClick={() => handlePage('next')}
          disabled={associates.length - perPage * (page + 1) <= 0}
        >
          Próximo
          <MdChevronRight size={20} />
        </button>
      </PageActions>
      <AssociateList>
        {associatesPerPage.length === 0 ? (
          <h1>Nehum movimento referente ao filtro.</h1>
        ) : (
          associatesPerPage.map(associate => (
            <li key={associate.user.id}>
              <h1>Associado: {associate.user.name.toLowerCase()}</h1>
              <div className="associate-total">
                <AssociateTotal color="#f00">
                  <p>R$</p>
                  <div>
                    <p>Comissão a confirmar</p>
                    <h2>{associate.user.total_commis_to_confirm}</h2>
                  </div>
                </AssociateTotal>
              </div>
              <div className="overflow-table">{renderTable(associate)}</div>
            </li>
          ))
        )}
      </AssociateList>
    </>
  );
}
