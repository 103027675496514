export function getAssociatesRequest(data) {
  return {
    type: '@associate/GET_ASSOCIATE_REQUEST',
    payload: { data },
  };
}

export function getAssociatesSucess(members) {
  return {
    type: '@associate/GET_ASSOCIATE_SUCCESS',
    payload: { members },
  };
}

export function getAssociatesFailure() {
  return {
    type: '@associate/GET_ASSOCIATE_FAILURE',
  };
}

export function getFinancesRequest(start, end) {
  return {
    type: '@associate/GET_FINANCES_REQUEST',
    payload: { start, end },
  };
}

export function getFinancesSuccess(finances) {
  return {
    type: '@associate/GET_FINANCES_SUCCESS',
    payload: { finances },
  };
}

export function getFinancesFailure() {
  return {
    type: '@associate/GET_FINANCES_FAILURE',
  };
}

export function getCustomersRequest() {
  return {
    type: '@associate/GET_CUSTOMERS_REQUEST',
  };
}

export function getCustomersSuccess(customers) {
  return {
    type: '@associate/GET_CUSTOMERS_SUCCESS',
    payload: { customers },
  };
}

export function getCustomersFailure() {
  return {
    type: '@associate/GET_CUSTOMERS_FAILURE',
  };
}
