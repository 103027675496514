import React from 'react';

import spinner from '~/assets/spinner.png';

import { Container } from './styles';

export default function LoadingSpinner() {
  return (
    <Container>
      <img src={spinner} alt="spinner" />
    </Container>
  );
}
