export function getPaymentsRequest(
  date_ini,
  date_fin,
  customers,
  dateFormatted
) {
  return {
    type: '@payment/GET_PAYMENTS_REQUEST',
    payload: { date_ini, date_fin, customers, dateFormatted },
  };
}

export function getPaymentsSuccess(payments) {
  return {
    type: '@payment/GET_PAYMENTS_SUCCESS',
    payload: { payments },
  };
}

export function getPaymentsFailure() {
  return {
    type: '@payment/GET_PAYMENTS_FAILURE',
  };
}
