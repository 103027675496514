/* eslint-disable prefer-const */
import { formatPrice, formatPriceRs } from '~/util/format';

export default function formatFinances(admin, finances, model, customers) {
  const date = new Date();

  function caulculateDiffDate(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  if (admin) {
    if (model === 'Total ativo') {
      let total_commission = 0;

      const auxCustomers = customers.map(customer => {
        let customer_commission = 0;
        let customer_commis_expected = 0;
        let ixc_id = '';
        finances.forEach(finance => {
          if (finance.higher_id === customer.id && finance.payment_value) {
            customer_commission += finance.commission;
            total_commission += finance.commission;
            customer_commis_expected += finance.commis_expected;
            ixc_id = `${finance.ixc_id}`;
          }
        });
        if (customer_commission > 0) {
          return {
            user: {
              ...customer,
              ixc_id,
              total_commission: formatPriceRs(customer_commission),
              total_commis_expected: formatPrice(customer_commis_expected),
            },
          };
        }
        return null;
      });

      return {
        customers: auxCustomers.filter(customer => customer !== null),
        total_commission: formatPriceRs(total_commission),
      };
    }
    if (model === 'Total inativo') {
      let total_commis_to_confirm = 0;

      const auxCustomers = customers.map(customer => {
        let customer_commis_to_confirm = 0;
        let customer_commis_expected = 0;
        finances.forEach(finance => {
          if (finance.higher_id === customer.id && !finance.payment_value) {
            customer_commis_expected += finance.commis_expected;
            customer_commis_to_confirm +=
              finance.commis_expected - finance.commission;
            total_commis_to_confirm +=
              finance.commis_expected - finance.commission;
          }
        });

        if (customer_commis_to_confirm > 0) {
          return {
            user: {
              ...customer,
              total_commis_expected: formatPrice(customer_commis_expected),
              total_commis_to_confirm: formatPriceRs(
                customer_commis_to_confirm
              ),
            },
          };
        }
        return null;
      });

      return {
        customers: auxCustomers.filter(customer => customer !== null),
        total_commis_to_confirm: formatPriceRs(total_commis_to_confirm),
      };
    }
    if (model === 'Extrato de recebidos') {
      let total_commission = 0;
      const auxCustomers = customers.map(customer => {
        let customer_commission = 0;
        let ixc_id = '';

        const auxFinances = finances.filter(
          finance => finance.higher_id === customer.id && finance.payment_value
        );

        const sonIds = [
          ...new Set(auxFinances.map(finance => finance.user.id)),
        ];
        const son_finances = sonIds.map(sonId => {
          const { user } = auxFinances.find(
            finance => finance.user.id === sonId
          );
          let commission = 0;

          auxFinances.forEach(finance => {
            if (sonId === finance.user.id) {
              total_commission += finance.commission;
              customer_commission += finance.commission;
              commission += finance.commission;
              ixc_id = `${finance.ixc_id}`;
            }
          });
          return {
            ...user,
            ixc_id,
            total_commission: formatPrice(commission),
          };
        });

        if (customer_commission > 0) {
          return {
            user: {
              ...customer,
              total_commission: formatPriceRs(customer_commission),
            },
            son_finances,
          };
        }
        return null;
      });

      return {
        customers: auxCustomers.filter(customer => customer !== null),
        total_commission: formatPriceRs(total_commission),
      };
    }
    if (model === 'Total desativado') {
      let total_commis_to_confirm = 0;
      const auxCustomers = customers.map(customer => {
        let customer_commis_to_confirm = 0;
        let ixc_id = '';

        const auxFinances = finances.filter(
          finance => finance.higher_id === customer.id && !finance.payment_value
        );
        const sonIds = [
          ...new Set(auxFinances.map(finance => finance.user.id)),
        ];
        const son_finances = sonIds.map(sonId => {
          const { user } = auxFinances.find(
            finance => finance.user.id === sonId
          );
          let commis_to_confirm = 0;
          auxFinances.forEach(finance => {
            if (sonId === finance.user.id) {
              total_commis_to_confirm +=
                finance.commis_expected - finance.commission;
              customer_commis_to_confirm +=
                finance.commis_expected - finance.commission;
              commis_to_confirm += finance.commis_expected - finance.commission;
              ixc_id = `${finance.ixc_id}`;
            }
          });

          return {
            ...user,
            ixc_id,
            total_commis_to_confirm: formatPrice(commis_to_confirm),
          };
        });

        if (customer_commis_to_confirm > 0) {
          return {
            user: {
              ...customer,
              total_commis_to_confirm: formatPriceRs(
                customer_commis_to_confirm
              ),
            },
            son_finances,
          };
        }
        return null;
      });

      return {
        customers: auxCustomers.filter(customer => customer !== null),
        total_commis_to_confirm: formatPriceRs(total_commis_to_confirm),
      };
    }
    if (model === 'Total inadimplente') {
      let total_commis_to_confirm = 0;

      const auxCustomers = customers.map(customer => {
        let customer_commis_to_confirm = 0;
        let customer_commis_expected = 0;
        finances.forEach(finance => {
          if (
            finance.higher_id === customer.id &&
            !finance.payment_value &&
            caulculateDiffDate(new Date(finance.due_date), date) > 30
          ) {
            customer_commis_expected += finance.commis_expected;
            customer_commis_to_confirm +=
              finance.commis_expected - finance.commission;
            total_commis_to_confirm +=
              finance.commis_expected - finance.commission;
          }
        });

        if (customer_commis_to_confirm > 0) {
          return {
            user: {
              ...customer,
              total_commis_expected: formatPrice(customer_commis_expected),
              total_commis_to_confirm: formatPriceRs(
                customer_commis_to_confirm
              ),
            },
          };
        }
        return null;
      });

      return {
        customers: auxCustomers.filter(customer => customer !== null),
        total_commis_to_confirm: formatPriceRs(total_commis_to_confirm),
      };
    }

    let total_commis_expected = 0;
    let total_commission = 0;
    const auxCustomers = customers.map(customer => {
      let customer_commis_expected = 0;
      let customer_commission = 0;

      const auxFinances = finances.filter(
        finance => finance.higher_id === customer.id
      );
      const sonIds = [...new Set(auxFinances.map(finance => finance.user.id))];
      const son_finances = sonIds.map(sonId => {
        let commis_expected = 0;
        let commission = 0;
        let ixc_id = '';
        auxFinances.forEach(finance => {
          if (sonId === finance.user.id) {
            total_commis_expected += finance.commis_expected;
            customer_commis_expected += finance.commis_expected;
            commis_expected += finance.commis_expected;
            total_commission += finance.commission;
            customer_commission += finance.commission;
            commission += finance.commission;
            ixc_id = `${finance.ixc_id}`;
          }
        });

        const { user } = auxFinances.find(finance => finance.user.id === sonId);
        return {
          ...user,
          ixc_id,
          total_commis_expected: formatPrice(commis_expected),
          total_commis_to_confirm: formatPrice(commis_expected - commission),
          total_commission: formatPrice(commission),
        };
      });

      return {
        user: {
          ...customer,
          total_commis_expected: formatPriceRs(customer_commis_expected),
          total_commis_to_confirm: formatPriceRs(
            customer_commis_expected - customer_commission
          ),
          total_commission: formatPriceRs(customer_commission),
        },
        son_finances,
      };
    });

    return {
      customers: auxCustomers,
      total_commis_expected: formatPriceRs(total_commis_expected),
      total_commis_to_confirm: formatPriceRs(
        total_commis_expected - total_commission
      ),
      total_commission: formatPriceRs(total_commission),
    };
  }

  if (model === 'Extrato de recebidos') {
    let total_commission = 0;
    const associateIds = [...new Set(finances.map(finance => finance.user.id))];
    const auxAssociates = associateIds.map(associate => {
      let associate_commission = 0;
      const auxFinances = finances.map(finance => {
        if (finance.user.id === associate && finance.payment_value) {
          associate_commission += finance.commission;
          total_commission += finance.commission;
          return {
            ...finance,
            formated_due_date: new Date(finance.due_date).toLocaleDateString(
              'pt-br'
            ),
            formated_payment_date: finance.payment_date
              ? new Date(finance.payment_date).toLocaleDateString('pt-br')
              : ' / / ',
            formated_value: formatPrice(finance.value),
            formated_payment_value: formatPrice(finance.payment_value),
            formated_commission: formatPrice(finance.commission),
          };
        }
        return null;
      });
      if (associate_commission > 0) {
        const { user } = finances.find(
          finance => finance.user.id === associate
        );
        return {
          user: {
            ...user,
            total_commission: formatPriceRs(associate_commission),
          },
          finances: auxFinances.filter(finance => finance !== null),
        };
      }
      return null;
    });
    return {
      associates: auxAssociates.filter(associate => associate !== null),
      total_commission: formatPriceRs(total_commission),
    };
  }
  if (model === 'Total ativo') {
    let total_commission = 0;
    const associateIds = [...new Set(finances.map(finance => finance.user.id))];
    const auxAssociates = associateIds.map(associate => {
      let associate_commission = 0;
      let associate_commis_expected = 0;
      finances.forEach(finance => {
        if (finance.user.id === associate && finance.payment_value) {
          associate_commission += finance.commission;
          associate_commis_expected += finance.commis_expected;
          total_commission += finance.commission;
        }
      });
      if (associate_commission > 0) {
        const { user, contract } = finances.find(
          finance => finance.user.id === associate
        );
        return {
          user: {
            ...user,
            total_commission: formatPriceRs(associate_commission),
            total_commis_expected: formatPrice(associate_commis_expected),
            contract,
          },
        };
      }
      return null;
    });

    return {
      associates: auxAssociates.filter(finance => finance !== null),
      total_commission: formatPriceRs(total_commission),
    };
  }
  if (model === 'Total inativo') {
    let total_commis_to_confirm = 0;
    const associateIds = [...new Set(finances.map(finance => finance.user.id))];
    const auxAssociates = associateIds.map(associate => {
      let associate_commis_to_confirm = 0;
      let associate_commis_expected = 0;
      finances.forEach(finance => {
        if (finance.user.id === associate && !finance.payment_value) {
          associate_commis_to_confirm +=
            finance.commis_expected - finance.commission;
          associate_commis_expected += finance.commis_expected;
          total_commis_to_confirm +=
            finance.commis_expected - finance.commission;
        }
      });
      if (associate_commis_to_confirm > 0) {
        const { user, contract } = finances.find(
          finance => finance.user.id === associate
        );
        return {
          user: {
            ...user,
            total_commis_to_confirm: formatPriceRs(associate_commis_to_confirm),
            total_commis_expected: formatPrice(associate_commis_expected),
            contract,
          },
        };
      }
      return null;
    });

    return {
      associates: auxAssociates.filter(finance => finance !== null),
      total_commis_to_confirm: formatPriceRs(total_commis_to_confirm),
    };
  }
  if (model === 'Total desativado') {
    let total_commis_to_confirm = 0;
    const associateIds = [...new Set(finances.map(finance => finance.user.id))];
    const auxAssociates = associateIds.map(associate => {
      let associate_commis_to_confirm = 0;
      const auxFinances = finances.map(finance => {
        if (finance.user.id === associate && !finance.payment_value) {
          associate_commis_to_confirm +=
            finance.commis_expected - finance.commission;
          total_commis_to_confirm +=
            finance.commis_expected - finance.commission;
          return {
            ...finance,
            formated_due_date: new Date(finance.due_date).toLocaleDateString(
              'pt-br'
            ),
            formated_payment_date: finance.payment_date
              ? new Date(finance.payment_date).toLocaleDateString('pt-br')
              : ' / / ',
            formated_value: formatPrice(finance.value),
            formated_payment_value: formatPrice(finance.payment_value),
            formated_commission: formatPrice(finance.commission),
          };
        }
        return null;
      });
      if (associate_commis_to_confirm > 0) {
        const { user } = finances.find(
          finance => finance.user.id === associate
        );
        return {
          user: {
            ...user,
            total_commis_to_confirm: formatPriceRs(associate_commis_to_confirm),
          },
          finances: auxFinances.filter(finance => finance !== null),
        };
      }
      return null;
    });
    return {
      associates: auxAssociates.filter(associate => associate !== null),
      total_commis_to_confirm: formatPriceRs(total_commis_to_confirm),
    };
  }
  if (model === 'Total inadimplente') {
    let total_commis_to_confirm = 0;
    const associateIds = [...new Set(finances.map(finance => finance.user.id))];
    const auxAssociates = associateIds.map(associate => {
      let associate_commis_to_confirm = 0;
      let associate_commis_expected = 0;
      finances.forEach(finance => {
        if (
          finance.user.id === associate &&
          !finance.payment_value &&
          caulculateDiffDate(new Date(finance.due_date), date) > 30
        ) {
          associate_commis_to_confirm +=
            finance.commis_expected - finance.commission;
          associate_commis_expected += finance.commis_expected;
          total_commis_to_confirm +=
            finance.commis_expected - finance.commission;
        }
      });
      if (associate_commis_to_confirm > 0) {
        const { user, contract } = finances.find(
          finance => finance.user.id === associate
        );
        return {
          user: {
            ...user,
            total_commis_to_confirm: formatPriceRs(associate_commis_to_confirm),
            total_commis_expected: formatPrice(associate_commis_expected),
            contract,
          },
        };
      }
      return null;
    });

    return {
      associates: auxAssociates.filter(finance => finance !== null),
      total_commis_to_confirm: formatPriceRs(total_commis_to_confirm),
    };
  }

  let total_commission = 0;
  let total_commis_expected = 0;
  const associateIds = [...new Set(finances.map(finance => finance.user.id))];
  const auxAssociates = associateIds.map(associate => {
    let associate_commission = 0;
    let associate_commis_expected = 0;
    const auxFinances = finances.map(finance => {
      if (finance.user.id === associate) {
        associate_commission += finance.commission;
        associate_commis_expected += finance.commis_expected;
        total_commission += finance.commission;
        total_commis_expected += finance.commis_expected;
        return {
          ...finance,
          formated_due_date: new Date(finance.due_date).toLocaleDateString(
            'pt-br'
          ),
          formated_payment_date: finance.payment_date
            ? new Date(finance.payment_date).toLocaleDateString('pt-br')
            : ' / / ',
          formated_value: formatPrice(finance.value),
          formated_payment_value: formatPrice(finance.payment_value),
          formated_commission: formatPrice(finance.commission),
        };
      }
      return null;
    });
    const { user } = finances.find(finance => finance.user.id === associate);
    return {
      user: {
        ...user,
        total_commission: formatPriceRs(associate_commission),
        total_commis_expected: formatPriceRs(associate_commis_expected),
        total_commis_to_confirm: formatPriceRs(
          associate_commis_expected - associate_commission
        ),
      },
      finances: auxFinances.filter(finance => finance !== null),
    };
  });
  return {
    associates: auxAssociates.filter(associate => associate !== null),
    total_commission: formatPriceRs(total_commission),
    total_commis_expected: formatPriceRs(total_commis_expected),
    total_commis_to_confirm: formatPriceRs(
      total_commis_expected - total_commission
    ),
  };
}
