import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { numberMask } from '~/services/mask';

import {
  getIndicatorRequest,
  sendMailRequest,
} from '~/store/modules/indicate/actions';

import logo_wnet from '~/assets/logo_wnet.png';
import LoadingSpinner from '~/components/LoadingSpinner/index';
import { Container } from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string()
    .email('Digite um e-mail válido ex(x@x.com)')
    .required('O email é obrigatório ex(x@x.com)'),
  number: Yup.string()
    .min(15, 'Digite um número valido ex((27) 9 9999-9999)')
    .required('O número é obrigatório ex((27) 9 9999-9999)'),
});

export default function Indicated() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.indicate.loading);
  const [numberInput, setNumberInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const indicator = useSelector(state => state.indicate.indicator);

  useEffect(() => {
    dispatch(getIndicatorRequest(window.location.pathname.split('/')[2]));
  }, [dispatch]);

  function numberChange(e) {
    setNumberInput(numberMask(e.target.value));
  }

  function handleSubmit({ name, email, number }) {
    const idIndicator = indicator.id;
    dispatch(sendMailRequest(name, idIndicator, email, number));
  }

  return (
    indicator && (
      <Container>
        {loading && <LoadingSpinner />}
        <div className="indicate-for">
          <img src={logo_wnet} alt="logo_wnet" />
          <h1>Você foi indicado por {indicator.name.split(' ', 1)}!</h1>
          <h3>Digite seus dados e entraremos em contato</h3>
        </div>
        <div className="form">
          <Form schema={schema} onSubmit={handleSubmit}>
            <Input name="name" placeholder="Nome Completo" />
            <Input
              name="email"
              value={emailInput}
              onChange={e => setEmailInput(e.target.value)}
              placeholder="Endereço de e-mail"
            />
            <Input
              maxLength="15"
              name="number"
              value={numberInput}
              onChange={numberChange}
              placeholder="Telefone"
            />
            <button type="submit">{loading ? 'Eviando...' : 'Enviar'}</button>
          </Form>
        </div>
      </Container>
    )
  );
}
