import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  format,
  subMonths,
  addMonths,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import pt from 'date-fns/locale/pt';

import { getPaymentsRequest } from '~/store/modules/payment/actions';

import LoadingSpinner from '~/components/LoadingSpinner/index';
import { Container } from './styles';

export default function Closure() {
  const dispatch = useDispatch();
  const customers = useSelector(state => state.associate.customers);
  const loading = useSelector(state => state.payment.loading);
  const [date, setDate] = useState(new Date());
  const dateFormatted = useMemo(
    () => format(date, "MMMM 'de' yyyy", { locale: pt }),
    [date]
  );

  function handlePrevMonth() {
    setDate(subMonths(date, 1));
  }

  function handleNextMonth() {
    setDate(addMonths(date, 1));
  }

  function handleSend() {
    const date_ini = format(startOfMonth(date), "yyyy'-'MM'-'dd", {
      locale: pt,
    });
    const date_fin = format(endOfMonth(date), "yyyy'-'MM'-'dd", {
      locale: pt,
    });
    dispatch(getPaymentsRequest(date_ini, date_fin, customers, dateFormatted));
  }

  return (
    <Container>
      {loading && <LoadingSpinner />}
      <h1>Fechamento Mensal de Comissões</h1>
      <p>Selecione abaixo o período que deseja realizar o fechamento</p>
      <div className="change-month">
        <button type="button" onClick={() => handlePrevMonth()}>
          &lt;
        </button>
        <h3>{dateFormatted}</h3>
        <button type="button" onClick={() => handleNextMonth()}>
          &gt;
        </button>
      </div>
      <button type="button" onClick={() => handleSend()}>
        Processar
      </button>
    </Container>
  );
}
