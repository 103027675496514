import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background: #fff;
  padding: 0 30px;
  width: 100%;

  @media only screen and (max-width: 750px) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  height: 64px;
  max-width: 1050px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
    align-content: center;

    img {
      margin-right: 20px;
      padding-right: 20px;
      height: 50px;
      border-right: 1px solid #eee;
      @media only screen and (max-width: 750px) {
        margin-right: 5px;
        padding-right: 5px;
      }
    }
  }
  aside {
    display: flex;
    align-items: center;
  }
`;

export const Route = styled.div`
  border-right: 1px solid #eee;
  padding: 10px;

  a {
    padding: 5px 0;
    font-weight: ${props => (props.path ? 'bold' : 'normal')};
    color: #1f7ecc;
    height: 30px;
    border-bottom: 1px solid
      ${props => (props.path ? '#1f7ecc' : 'transparent')};
  }

  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

export const Indicate = styled.button`
  width: 90px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #ff892e;
  padding: 5px;
  border: none;
  border-radius: 20px;
  margin-right: 30px;

  @media only screen and (max-width: 750px) {
    margin-right: 5px;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  a {
    font-size: 16px;
    color: #fff;
  }

  &:hover {
    background: ${darken(0.1, '#ff892e')};
  }
`;

export const Profile = styled.div`
  display: flex;
  margin: 0 10px;
  padding-left: 20px;
  border-left: 1px solid #eee;

  @media only screen and (max-width: 750px) {
    margin: 0 5px;
    padding-left: 5px;
  }

  div {
    text-align: right;
    margin-right: 10px;

    @media only screen and (max-width: 750px) {
      margin-right: 5px;
    }

    strong {
      display: block;
      color: #333;
      text-transform: capitalize;
    }

    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      font-weight: ${props => (props.path ? 'bold' : '100px')};
      color: ${props => (props.path ? '#1f7ecc' : '#999')};
      border-bottom: 1px solid
        ${props => (props.path ? '#1f7ecc' : 'transparent')};
    }
  }

  img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }
`;

export const HambMenu = styled.button`
  display: none;
  background: none;
  border: none;

  div {
    width: 35px;
    height: 5px;
    background-color: #1f7ecc;
    margin: 6px 0;
    transition: 0.4s;
  }
  ul {
    list-style: none;
    position: absolute;
    margin-top: 20px;
    margin-left: ${props => (props.admin ? '-70px' : '-60px')};
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;

    div {
      content: '';
      background: transparent;
      position: absolute;
      right: 5px;
      top: -26px;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid rgba(0, 0, 0, 0.8);
    }

    li {
      margin: 0px 10px;
      padding: 15px 0px;
      border-bottom: 1px solid #eee;

      a {
        color: #fff;
        font-weight: bold;
      }
    }
    li:last-child {
      border: none;
    }
  }

  @media only screen and (max-width: 750px) {
    display: inline;
  }
`;
