import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import logo from '~/assets/logo_wnet.png';

import { cnpjMask, cpfMask } from '~/services/mask';

import { recoveryRequest } from '~/store/modules/auth/actions';

const schema = Yup.object().shape({
  cpf: Yup.string().required('O cpf é obrigatório'),
});

export default function SignUp() {
  const dispatch = useDispatch();
  const [documentId, setDocumentId] = useState('');

  function handleSubmit({ cpf = documentId }) {
    dispatch(recoveryRequest(cpf));
  }

  function handleChange(e) {
    setDocumentId(
      e.target.value.length > 14
        ? cnpjMask(e.target.value)
        : cpfMask(e.target.value)
    );
  }

  return (
    <>
      <img src={logo} alt="wnet" />

      <Form schema={schema} onSubmit={handleSubmit}>
        <Input
          maxLength="18"
          name="cpf"
          value={documentId}
          onChange={handleChange}
          placeholder="Seu CPF / CNPJ"
        />

        <button type="submit">Recuperar</button>
        <Link to="/">Voltar ao login</Link>
      </Form>
    </>
  );
}
