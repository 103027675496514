import produce from 'immer';

const INITIAL_STATE = {
  indicator: null,
  loading: false,
};

export default function associate(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@indicate/GET_INDICATOR_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@indicate/GET_INDICATOR_SUCCESS': {
        draft.loading = false;
        draft.indicator = action.payload.indicator;
        break;
      }
      case '@indicate/GET_INDICATOR_FAILURE': {
        draft.loading = false;
        draft.indicator = null;
        break;
      }
      case '@indicate/SEND_MAIL_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@indicate/SEND_MAIL_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@indicate/SEND_MAIL_FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
