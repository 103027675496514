import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import {
  getIndicatorSuccess,
  getIndicatorFailure,
  sendMailSuccess,
  sendMailFailure,
} from './actions';

export function* getIndicatorRequest({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(api.get, `users/${id}`);
    const { data: indicator } = response;
    yield put(getIndicatorSuccess(indicator));
  } catch {
    toast.error('Indicador não encontrado');
    yield put(getIndicatorFailure());
    history.push('/');
  }
}

export function* sendMailRequest({ payload }) {
  const { name, idIndicator, email, phone } = payload;
  try {
    yield call(api.post, '/indicate', {
      name,
      idIndicator,
      email,
      phone,
    });
    toast.success('E-mail enviado com sucesso');
    yield put(sendMailSuccess());
  } catch {
    toast.error('Falha ao enviar o e-mail');
    yield put(sendMailFailure());
  }
}

export default all([
  takeLatest('@indicate/GET_INDICATOR_REQUEST', getIndicatorRequest),
  takeLatest('@indicate/SEND_MAIL_REQUEST', sendMailRequest),
]);
